import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Pagination from "@material-ui/lab/Pagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InfoTableHeaderCell from "./info-table-header-cell";
import { SampleListItemKey } from "../../ducks/samples";
import { TaxonListItemKey } from "../../ducks/species";
import { SamplingEventListItemKey } from "../../ducks/events";
import { FrontendDartOrderListItemKey, DartOrderListItemSpeciesKey } from "../../ducks/orders";
import { setPaginationPage, SortDirection, DetailPageCorrectionKey } from "../../ducks/constant-data";
import { useDispatch } from "react-redux";
import { PaginationInfo } from "rbgds-shared/types/responses";
import InfoTableRow from "./info-table-row";
import { AppPage } from "../../ducks/ui";
import { getPaginationCaptionInfo } from "./info-table-helpers";
import { ImportRecordListItemKey } from "../../ducks/imports";

// Types
export type TableCellAlignment = "left" | "right";
type ErrorKey = "error";
export type ColumnKey =
    | SampleListItemKey
    | TaxonListItemKey
    | SamplingEventListItemKey
    | FrontendDartOrderListItemKey
    | DartOrderListItemSpeciesKey
    | ErrorKey
    | ImportRecordListItemKey
    | DetailPageCorrectionKey;

export type TableColumn = {
    label: string;
    key: ColumnKey;
    align: TableCellAlignment;
    widthFraction?: number;
    linkKey?: ColumnKey;
    path?: string;
    subPathKey?: ColumnKey;
    truncate?: number;
    format?: (row: any, key: ColumnKey) => JSX.Element;
};

const useStyles = makeStyles(theme => ({
    paginationWrapper: {
        display: "grid",
        gridTemplateColumns: "150px 1fr 150px",
        marginTop: 35,
        height: 30,
        alignItems: "center",
    },
    table: {
        tableLayout: "auto",
    },
}));

type InfoTableProps = {
    rows: any;
    columns: TableColumn[];
    sortableColumns: ColumnKey[];
    backToPage: AppPage;
    subColumns?: TableColumn[];
    pagination?: PaginationInfo;
    sortBy?: ColumnKey;
    sortDirection?: SortDirection;
    dataCy?: string;
};

function InfoTable({
    rows,
    columns,
    sortableColumns,
    backToPage,
    subColumns,
    pagination,
    sortBy,
    sortDirection,
    dataCy,
}: InfoTableProps) {
    const classes = useStyles();
    const dispatch = useDispatch();

    // Numbers for count indicator
    const { totalResults, startIndex, endIndex } = getPaginationCaptionInfo(pagination);

    return (
        <div data-cy={dataCy}>
            {rows && (
                <>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {columns.map(col => {
                                    return (
                                        <InfoTableHeaderCell
                                            label={col.label}
                                            columnKey={col.key}
                                            align={col.align}
                                            isSortable={sortableColumns.includes(col.key)}
                                            sortBy={sortBy}
                                            sortDirection={sortDirection}
                                            key={col.key}
                                        />
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row: any, i: number) => (
                                <InfoTableRow
                                    rowIndex={i}
                                    columns={columns}
                                    row={row}
                                    key={`row-${i}`}
                                    isCollapsible={row.serviceNumber}
                                    subRows={row.species}
                                    subColumns={subColumns}
                                    backToPage={backToPage}
                                />
                            ))}
                        </TableBody>
                    </Table>

                    {pagination && (
                        <div className={classes.paginationWrapper}>
                            <div></div>
                            <div style={{ display: "grid" }}>
                                <Pagination
                                    count={pagination?.numPages}
                                    style={{ justifySelf: "center" }}
                                    color="secondary"
                                    page={pagination?.page ? pagination.page : 1}
                                    onChange={(event: object, page: number) => {
                                        dispatch(setPaginationPage(page));
                                    }}
                                />
                            </div>
                            <div style={{ width: "100%", textAlign: "right" }}>
                                <span>{`${startIndex}-${endIndex} / ${totalResults.toLocaleString()}`}</span>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default InfoTable;
