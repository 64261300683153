import { PaginatedResultSet } from ".";
import { SampleListItemType } from "./sample-responses";

export enum SamplingEventSource {
    IFORMS = "iForms",
    MANUAL = "Manual",
    NEW_APP = "New app",
    AIBS_APP = "AIBS app",
}

export type SamplingEventListItem = {
    eventKey: string;
    peoplePresent: string | null;
    locality: string | null;
    eventDate: string | null;
} & SamplingEventListItemCounts;

export type SamplingEventListItemCounts = {
    speciesCount?: number;
    tissueSampleCount?: number;
    voucherSampleCount?: number;
};

export type SamplingEventListResponse = PaginatedResultSet<SamplingEventListItem>;

export type SamplingEventDetail = {
    readonly eventKey: string;
    readonly eventID: string | null;
    readonly eventDate: string | null;
    readonly eventSource: SamplingEventSource | null;
    readonly peoplePresent: string | null;
    locality: string | null;
    soilColour: string | null;
    soilTexture: string | null;
    disturbanceHistory: string | null;
    otherDisturbance: string | null;
    landformPrimary: string | null;
    otherLandform: string | null;
    habitat: string | null;
    otherHabitat: string | null;
    associatedVegetation: string | null;
    siteNotes: string | null;
    species: SamplingEventDetailLinkedSpecies[];
    // Tenure
    nationalPark: string | null;
    natureReserve: string | null;
    stateConservationArea: string | null;
    stateForest: string | null;
    otherTenure: string | null;
};

export type SamplingEventDetailLinkedSpecies = {
    taxonKey: string;
    acceptedName: string;
    samples: SamplingEventDetailLinkedSample[];
};

export type SamplingEventDetailLinkedSample = {
    id: string;
    type: SampleListItemType;
    archived: boolean;
};

export type SamplingEventPatch = {
    locality?: string | null;
    soilColour?: string | null;
    soilTexture?: string | null;
    disturbanceHistory?: string | null;
    otherDisturbance?: string | null;
    landformPrimary?: string | null;
    otherLandform?: string | null;
    habitat?: string | null;
    associatedVegetation?: string | null;
    siteNotes?: string | null;
    // Tenure
    nationalPark?: string | null;
    natureReserve?: string | null;
    stateConservationArea?: string | null;
    stateForest?: string | null;
    otherTenure?: string | null;
};

export type SamplingEventPost = {
    eventKey: string;
    eventID: string;
    eventDate: string;
    eventSource: SamplingEventSource;
};

export enum LandformsPrimary {
    CLIFF = "Cliff",
    DEPRESSION = "Depression",
    DUNE = "Dune",
    FLAT = "Flat",
    GULLY = "Gully",
    RIDGE = "Ridge",
    ROCKY = "Rocky",
    SLOPE = "Slope",
    UNDULATING = "Undulating",
    WATER_EDGE = "Water edge",
    SUBMERGED = "Submerged",
}

export enum DisturbanceHistoryPrimary {
    UNDISTURBED = "Undisturbed",
    RECENT_FIRE = "Recent Fire (1-3 years)",
    PAST_FIRE = "Historic Fire (3+ years)",
    CLEARED = "Logged/cleared",
    WEED_INFESTED = "Weed infestation",
    ROADSIDE = "Track/Roadside disturbed",
    STORM = "Severe Storm/Cyclone",
    FARMLAND = "Farmland influence",
    NOT_SURE = "Not Sure",

    RECENT_FIRE_LEGACY = "Recent Fire",
    PAST_FIRE_LEGACY = "Past Fire",
}

export enum HabitatPrimary {
    DRY_EUCALYPT = "Dry eucalypt forest and woodland",
    WET_EUCALYPT = "Wet eucalypt forest and woodland",
    NON_EUCALYPT = "Non-eucalypt forest and woodland",
    RAINFOREST = "Rainforest and related shrub",
    ARID_SHRUBLAND = "Arid shrubland",
    SHRUB = "Heathland and coastal complexes",
    GRASSLAND = "Native grassland",
    SALTMARSH_SWAMPS = "Saltmarsh, wetlands and swamps",
    HIGHLAND = "Highland treeless vegetation",
    CLEARED = "Cleared/non-native vegetation",

    SHRUB_LEGACY = "Shrub heathland and coastal complexes",
    NON_EUCALYPT_LEGACY = "Non eucalypt forest and woodland",
    SALTMARSH_LEGACY = "Saltmarsh wetlands",
    SWAMPS_LEGACY = "Swamps",
    DISTURBED_LEGACY = "Disturbed",
}

export enum SoilTextures {
    HUMUS_RICH = "Humus rich",
    SAND = "Sand",
    SANDY_LOAM = "Sandy Loam",
    LOAM = "Loam",
    CLAY_LOAM = "Clay Loam",
    CLAY = "Clay",
    SILT = "Silt",
    PEAT = "Peat",
    VOLCANIC = "Volcanic",
    NOT_SURE_LEGACY = "Not Sure",
}

export enum SoilColours {
    BLACK = "Black",
    YELLOW = "Yellow",
    GREY = "Grey",
    ORANGE = "Orange",
    DARK_BROWN = "Dark brown",
    LIGHT_BROWN = "Light brown",
    RED = "Red",

    BROWN_LEGACY = "Brown",
}

export enum SiteAspect {
    NORTH = "North",
    NORTH_EAST = "North-east",
    EAST = "East",
    SOUTH_EAST = "South-east",
    SOUTH = "South",
    SOUTH_WEST = "South-west",
    WEST = "West",
    NORTH_WEST = "North-west",
}
