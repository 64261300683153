import { letterToNumber, numberToLetter } from "./numberToLetter";

type Coords3d = { row: number; col: number; plate: number };
type HumanisedPlatePosition = { row: string; column: number; plateNumber: number };

// e.g. 2*3 grid with 2 plates
// 0 => 0,0-0
// 1 => 1,0-0
// 2 => 0,1-0
// 3 => 1,1-0
// 4 => 0,2-0
// 5 => 1,2-0
// 6 => 0,0-1
// 7 => 1,0-1
// 8 => 0,1-1
// 9 => 1,1-1
// 10 => 0,2-1
// 11 => 1,2-1

// Turns an index into a grid position
// Since there are multiple grids (plates), it's effectively a 3d matrix
export const numberTo3dCoords = (
    index: number,
    height: number,
    width: number,
    numPlates: number
): Coords3d => {
    const gridSize = width * height;
    const totalWells = gridSize * numPlates;
    const rowIndex = index % height;
    const colIndex = Math.floor((index % gridSize) / height);
    const plateIndex = Math.floor(index / gridSize);

    if (plateIndex >= totalWells || index < 0) {
        throw new Error(
            `Index out of range in numberTo3dCoords. Expected 0 > index > ${totalWells}, got index = ${index}`
        );
    }
    return { row: rowIndex, col: colIndex, plate: plateIndex };
};

// The humanised display version of the above
export const numberToPlatePosition = (
    index: number,
    height: number,
    width: number,
    numPlates: number
): HumanisedPlatePosition => {
    const indexCoords = numberTo3dCoords(index, height, width, numPlates);
    return {
        row: numberToLetter(indexCoords.row),
        column: indexCoords.col + 1,
        plateNumber: indexCoords.plate + 1,
    };
};

// Turns a row / col / plateNumber into a single absolute index
export const plateCoordsToIndex = (
    row: string,
    column: number,
    plateNumber: number,
    plateHeight: number,
    plateWidth: number
): number => {
    const colIndex = column - 1;
    const rowIndex = letterToNumber(row);
    const plateIndex = plateNumber - 1;
    const numWellsInPlate = plateHeight * plateWidth;

    if (colIndex < 0 || rowIndex < 0 || plateIndex < 0) {
        throw new Error("plateCoordsToIndex: Index out of range");
    }
    const absoluteIndex = numWellsInPlate * plateIndex + plateHeight * colIndex + rowIndex;
    return absoluteIndex;
};
