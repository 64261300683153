import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { StateSamplingStatus } from "rbgds-shared";

const useStyles = makeStyles(theme => ({
    formControl: {
        width: "100%",
        minWidth: 120,
        maxWidth: 700,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export type StateSamplingStatusKey = keyof StateSamplingStatus;
export type FilterListItem = {
    key: string | StateSamplingStatusKey;
    label: string;
    warning?: string;
    isLegacyValue?: boolean;
};

type FilterDropdownProps = {
    label: string;
    value?: string;
    allLabel?: string;
    menuItems?: FilterListItem[];
    handleChange: (event: any) => void;
    options?: {
        removeEmptyOption: boolean;
    };
};

function FilterDropdown({
    handleChange,
    menuItems,
    label,
    value,
    options,
    allLabel,
}: FilterDropdownProps) {
    const classes = useStyles();

    const getOptionLabel = (option: FilterListItem) => {
        // Mark legacy values from the old app as such
        if (option.isLegacyValue) {
            return `${option.label} [legacy value]`;
        }

        return option.label;
    };

    return (
        <div style={{ marginBottom: 10, marginTop: 15, maxWidth: "100%", width: "100%" }}>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id={`select-outlined-label-${label}`}>{label}</InputLabel>
                <Select
                    labelId={`select-outlined-label-${label}`}
                    id={`select-outlined-${label}`}
                    value={value || ""}
                    onChange={handleChange}
                    label={label}
                >
                    {!options?.removeEmptyOption && (
                        <MenuItem value="">
                            <em>{allLabel || "All"}</em>
                        </MenuItem>
                    )}

                    {menuItems &&
                        menuItems.map((m, i) => (
                            <MenuItem value={m.label} key={String(m.key)}>
                                {getOptionLabel(m)}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default FilterDropdown;
