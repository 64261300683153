import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function LoadingOverlay() {
    return (
        <div
            style={{
                position: "absolute",
                zIndex: 1,
                backgroundColor: "rgba(0,0,0,0.1)",
                width: "calc(100% + 32px)",
                height: "calc(100% + 32px)",
                top: -16,
                left: -16,
                display: "grid",
                alignItems: "center",
                justifyItems: "center",
            }}
        >
            <CircularProgress color={"secondary"} style={{ height: 100, width: 100 }} />
        </div>
    );
}

export default LoadingOverlay;
