import { PaginationInfo } from "rbgds-shared/types/responses";

type PaginationCaptionInfo = {
    startIndex: number;
    endIndex: number;
    totalResults: number;
};

const FALLBACK_PAGE_SIZE = 25;

// Info about the results shown for pagination, e.g. "1-25 / 356"
// This could probably be written better.
export const getPaginationCaptionInfo = (
    pagination: PaginationInfo | undefined
): PaginationCaptionInfo => {
    const totalResults = pagination?.totalResults;
    let startIndex = pagination ? 1 + (pagination?.page - 1) * pagination?.pageSize : 1;
    let endIndex = pagination ? pagination?.page * pagination?.pageSize : FALLBACK_PAGE_SIZE;
    if (totalResults && endIndex > totalResults) endIndex = totalResults;

    if (totalResults === 0) {
        startIndex = 0;
        endIndex = 0;
    }
    return {
        startIndex: startIndex,
        endIndex: endIndex,
        totalResults: totalResults || 0,
    };
};
