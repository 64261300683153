import { Coord } from "../../components/coordinates-field/coordinates-field";
import { DataError, DataErrorFieldMessages } from "../../types/form-types";
import { Point } from "geojson";
import { strings } from "../strings/strings";
const { errorStrings } = strings;

// EXPORTED FUNCTIONS

export const checkValidLatLongAsync = async (value: Point | null): Promise<DataError> => {
    return checkValidLatLong(value);
};

export const checkValidLatLong = (value: Point | null): DataError => {
    const lat = value?.coordinates ? value.coordinates[1].toString() : null;
    const long = value?.coordinates ? value.coordinates[0].toString() : null;

    const latError = checkCoordinate("lat", lat);
    const longError = checkCoordinate("long", long);
    const latErrorMsg = latError.message || "";
    const longErrorMsg = longError.message || "";

    const fieldMessages: DataErrorFieldMessages = new Map([
        ["lat", latError],
        ["long", longError],
    ]);

    const ret: DataError = {
        error: latError.error || longError.error,
        message: `${latErrorMsg} ${longErrorMsg}`,
        fieldMessages: fieldMessages,
    };

    return ret;
};

// HELPER FUNCTIONS

// Because JS parseFloat says pretty much anything is a float
// https://stackoverflow.com/questions/12467542/how-can-i-check-if-a-string-is-a-float
export function isFloat(val: string): boolean {
    let isFloat = true;
    const floatRegex = /^-?[0-9]*\.?[0-9]*$/;
    if (!floatRegex.test(val)) isFloat = false;
    if (isNaN(parseFloat(val))) isFloat = false;
    return isFloat;
}

export function hasSingleTrailingDecimalPoint(val: string): boolean {
    const trailingDecimalRegex = /^[-+]?[0-9]*\.$/;
    return trailingDecimalRegex.test(val);
}

// Takes a coordinate value and a coordinate type
// Returns DataError type with the error message set if need
function checkCoordinate(coordType: Coord, valueString: string | null): DataError {
    if (!valueString || valueString === "" || valueString === "-") {
        return { error: true, message: `Missing ${coordType}itude.` };
    } else if (hasSingleTrailingDecimalPoint(valueString)) {
        return { error: false };
    } else if (!isFloat(valueString)) {
        return { error: true, message: `Invalid ${coordType}itude.` };
    } else if (
        coordType === "lat" &&
        (parseFloat(valueString) < -90 || parseFloat(valueString) > 90)
    ) {
        return { error: true, message: errorStrings.validation.latOutOfRange };
    } else if (
        coordType === "long" &&
        (parseFloat(valueString) < -180 || parseFloat(valueString) > 180)
    ) {
        return { error: true, message: errorStrings.validation.longOutOfRange };
    } else {
        return { error: false };
    }
}
