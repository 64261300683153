import { Point } from "geojson";

export enum MapItemType {
    TissueWithDart = "Tissue (Sent to DArT)",
    TissueNoDart = "Tissue (No DaRT)",
    Voucher = "Voucher",
    IncidentalObservation = "Incidental Observation",
    ALAHerbarium = "ALA Herbarium",
    NSWVisSurvey = "NSW VIS Survey",
}

export enum OcurrenceType {
    Herbarium = "Herbarium",
    Survey = "Survey",
}

// ----

export type MapItemBase = {
    type: MapItemType;
    acceptedName: string | null;
    locality: string | null;
    sampleLocation: Point;
};

export type MapItemObservation = MapItemBase & {
    type:
        | MapItemType.TissueWithDart
        | MapItemType.TissueNoDart
        | MapItemType.Voucher
        | MapItemType.IncidentalObservation;
    /**
     * ID is either the NSW number or observation ID, dependent on type.
     */
    id: string;
    NSWNumber?: string;
    observationId?: string;
    associatedProjects: string | null;
    peoplePresent: string | null;
    sampleDate: string | null;
};

export type MapItemOcurrence = MapItemBase & {
    type: MapItemType.ALAHerbarium | MapItemType.NSWVisSurvey;
    siteKey: string;
    year: number | null;
};

export type MapItem = MapItemObservation | MapItemOcurrence;

export type MapResponse = MapItem[];
