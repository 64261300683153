import React from "react";
import { useParams } from "react-router-dom";
import { DartOrderPatch, DartOrderDetail } from "rbgds-shared";
import { useDetailForm } from "../../hooks/useDetailForm";
import DetailPage from "../../components/detail-page/detail-page";
import WellPlateForm from "../../components/well-plate/well-plate-form";
import { orderForm } from "./orders-detail-page-config";
import ActionButton from "../../components/button/action-button";
import { api } from "../../modules/api";
import { history } from "../../pages/root";

function OrderDetailPage() {
    let { id } = useParams();

    const {
        displayData,
        patchData,
        setPatchValue,
        undoUnsavedChanges,
        submit,
        errors,
        savingStatus,
    } = useDetailForm<DartOrderDetail, DartOrderPatch>(id, "dart-order", "dart-order", orderForm);

    const handleDelete = () => {
        if (
            window.confirm(
                `This is an irreversible operation! Are you sure you want to delete DArT order "${id}"?`
            )
        ) {
            api(`dart-order/${id}`, {
                method: "DELETE",
            }).then(() => {
                history.push({
                    pathname: `/dashboard/orders`,
                });
            });
        }
    };

    return (
        <div>
            {displayData && (
                <DetailPage
                    id={id}
                    header={"DArT Order: " + displayData.serviceNumber || ""}
                    formSections={orderForm}
                    displayData={displayData}
                    patchData={patchData}
                    submit={submit}
                    setPatchValue={setPatchValue}
                    undoUnsavedChanges={undoUnsavedChanges}
                    errors={errors}
                    backToPage={"order details"}
                    detailPagePath={"orders"}
                    savingStatus={savingStatus}
                />
            )}
            <WellPlateForm id={id} displayData={displayData} setPatchValue={setPatchValue} />
            <div style={{ marginTop: 80 }}>
                <ActionButton label="Delete Order" severity="danger" onClick={handleDelete} />
            </div>
        </div>
    );
}

export default OrderDetailPage;
