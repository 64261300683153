import React from "react";
import { FilterListItem } from "../filter-dropdown/filter-dropdown";
import AutocompleteSelect from "../detail-page-autocomplete/autocomplete-select";
import {
    taxonTypes,
    provisionalStatusTypes,
    trueOrFalseTypes,
} from "../../modules/listItemsFromEnums";

type SpeciesMenuProps = {
    activeGenus?: string;
    activeSpecies?: string;
    activeTaxonType?: string;
    activeProvisionalStatus?: string;
    activeArchivedStatus?: string;
    handleGenusChange: (event: any) => void;
    handleSpeciesChange: (event: any) => void;
    handleTaxonTypeChange: (event: any) => void;
    handleProvisionalStatusChange: (event: any) => void;
    handleArchivedStatusChange: (event: any) => void;
    genera?: FilterListItem[];
    species?: FilterListItem[];
};

function SpeciesMenu({
    activeGenus,
    activeSpecies,
    activeTaxonType,
    activeProvisionalStatus,
    activeArchivedStatus,
    handleGenusChange,
    handleSpeciesChange,
    handleTaxonTypeChange,
    handleProvisionalStatusChange,
    handleArchivedStatusChange,
    genera,
    species,
}: SpeciesMenuProps) {
    return (
        <>
            <AutocompleteSelect
                label="Genus"
                value={activeGenus || ""}
                handleChange={handleGenusChange}
                menuItems={genera}
            />
            {activeGenus && (
                <AutocompleteSelect
                    label="Species"
                    value={activeSpecies || ""}
                    handleChange={handleSpeciesChange}
                    menuItems={species}
                />
            )}
            <AutocompleteSelect
                label="Taxon Type"
                value={activeTaxonType || ""}
                handleChange={handleTaxonTypeChange}
                menuItems={taxonTypes}
            />
            {activeTaxonType === "Provisional" && (
                <AutocompleteSelect
                    label="Provisional Status"
                    value={activeProvisionalStatus || ""}
                    handleChange={handleProvisionalStatusChange}
                    menuItems={provisionalStatusTypes}
                />
            )}
            <AutocompleteSelect
                label="Archived"
                value={activeArchivedStatus || "False"}
                handleChange={handleArchivedStatusChange}
                menuItems={trueOrFalseTypes}
            />
        </>
    );
}

export default SpeciesMenu;
