import Cookies from "js-cookie";

let baseAPIUrl: string = "http://localhost:8000/api/db";
if (process.env.REACT_APP_BRANCH_TAG === "main") {
    baseAPIUrl = "https://rbg-rnr-database.visualise.today/api/db";
} else if (process.env.REACT_APP_BRANCH_TAG === "staging") {
    baseAPIUrl = "https://rbg-rnr-django.staging1.ixchosted.com/api/db";
}

export const BASE_API_URL = baseAPIUrl;
export const apiVersion = process.env.REACT_APP_API_VERSION || "v1";

export type DetailPath =
    | "voucher-sample"
    | "tissue-sample"
    | "observation"
    | "sampling-event"
    | "dart-order"
    | "taxon"
    | "samples"
    | "import"
    | "import/manual"
    | "import/repair";

export type DetailPagePath =
    | "samples/tissue"
    | "samples/incidental-observation"
    | "samples/voucher"
    | "events"
    | "species"
    | "orders"
    | "import"
    | "import/manual"
    | "import/repair";

export const getCsrfToken = () => {
    const cookieValue = document.cookie.match("(^|;)\\s*csrftoken\\s*=\\s*([^;]+)");
    return cookieValue ? cookieValue.pop() : "";
};

export const api = async (
    path: string,
    requestOptions?: RequestInit,
    includeCSRF = false
): Promise<any> => {
    const url = `${BASE_API_URL}/${path}`;
    const options = requestOptions || {};
    const headers: { [key: string]: any } = {
        ...options.headers,
        Accept: "application/json",
    };

    if (includeCSRF) {
        headers["X-CSRFToken"] = Cookies.get("csrftoken") || "";
    } else {
        headers["Content-Type"] = "application/json";
    }

    const res = await fetch(url, {
        ...options,
        credentials: "include",
        headers: headers,
        body: options.body,
    });

    return res;
};
