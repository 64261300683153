import { TaxonDetail, TaxonPatch } from "rbgds-shared";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDetailForm } from "../../hooks/useDetailForm";
import DetailPage from "../../components/detail-page/detail-page";
import DetailPageCorrections from "../../components/detail-page-corrections/detail-page-corrections";
import { speciesForm } from "./species-detail-config";

import { api } from "../../modules/api";
import { history } from "../../pages/root";
import DetailFormFieldWrapper from "../../components/detail-form/detail-form-field-wrapper";
import ArchiveButton from "../../components/button/archive-button";

function SpeciesDetailPage() {
    let { id }: any = useParams();

    // TODO: move this to redux
    const [archiveError, setArchiveError] = useState(false);
    const [canArchiveSpecies, setCanArchiveSpecies] = useState(false);

    const {
        displayData,
        patchData,
        setPatchValue,
        undoUnsavedChanges,
        submit,
        errors,
        savingStatus,
    } = useDetailForm<TaxonDetail, TaxonPatch>(id, "taxon", "taxon", speciesForm);

    useEffect(() => {
        const hasActiveRelatedSamples = async () => {
            // Check that there are no related samples
            const response = await api(`species-has-related-samples/${id}`);
            const responseAsJson = await response.json();
            const hasActiveRelatedSamples = responseAsJson.hasActiveRelatedSamples;

            if (!hasActiveRelatedSamples) setCanArchiveSpecies(true);
        };

        if (id) hasActiveRelatedSamples();
    }, [id]);

    const handleArchive = () => {
        if (
            window.confirm(
                `Are you sure you want to archive Species "${displayData["acceptedName"]}"?`
            )
        ) {
            api(
                `taxon/archive/${id}`,
                {
                    method: "POST",
                },
                true
            ).then(data => {
                if (data.status === 200) {
                    history.push({
                        pathname: `/dashboard/species`,
                    });
                } else {
                    setArchiveError(true);
                }
            });
        }
    };

    const getHeaderTitle = () => {
        const archivedString = displayData["archived"] ? " [ARCHIVED]" : "";

        return `Taxon: ${displayData.acceptedName || ""}${archivedString}`;
    };

    return (
        <div>
            {displayData && (
                <div>
                    <DetailPage
                        id={id}
                        header={getHeaderTitle()}
                        formSections={speciesForm}
                        displayData={displayData}
                        patchData={patchData}
                        submit={submit}
                        setPatchValue={setPatchValue}
                        undoUnsavedChanges={undoUnsavedChanges}
                        errors={errors}
                        savingStatus={savingStatus}
                        detailPagePath={"species"}
                        backToPage={"species details"}
                    />

                    {Boolean(displayData.corrections?.length) && (
                        <DetailPageCorrections corrections={displayData.corrections} />
                    )}

                    {displayData.provisionalAcceptedStatus === "Not accepted" && (
                        <div style={{ marginTop: 80 }}>
                            <DetailFormFieldWrapper
                                body={
                                    <ArchiveButton
                                        modelBaseToArchive="species"
                                        handleArchive={handleArchive}
                                        isArchived={displayData["archived"]}
                                        archiveError={archiveError}
                                        cantArchive={!canArchiveSpecies}
                                    />
                                }
                                isReadOnly={true}
                                error={{ error: archiveError }}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default SpeciesDetailPage;
