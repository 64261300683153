import React, { useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles(theme => ({
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "auto",
        maxWidth: 500,
        marginBottom: theme.spacing(3),
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#BBB",
    },
    clearIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        width: 30,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#BBB",
        right: 65,
        top: 0,
        cursor: "pointer",
    },
    inputRoot: {
        color: "inherit",
        backgroundColor: "rgba(0,0,0,0.05)",
        height: "56px",
        borderRadius: "28px",
        width: "450px",
        maxWidth: "100%",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
    },
}));

type SearchBarProps = {
    onKeyUp: (newInputValue: string) => void;
    query: string | undefined;
    placeHolder: string;
};

function SearchBar({ onKeyUp, placeHolder, query }: SearchBarProps) {
    const classes = useStyles();

    const [inputVal, setInputVal] = useState(query);

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder={placeHolder}
                value={inputVal || ""}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                onChange={e => {
                    setInputVal(e.target.value);
                }}
                onKeyUp={(e: any) => {
                    onKeyUp(e.target.value);
                }}
            />
            {inputVal && (
                <div
                    className={classes.clearIcon}
                    onClick={() => {
                        setInputVal("");
                        onKeyUp("");
                    }}
                >
                    <ClearIcon />
                </div>
            )}
        </div>
    );
}

export default SearchBar;
