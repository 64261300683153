import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
        neutral: Palette["primary"];
        icon: Palette["primary"];
    }
    interface PaletteOptions {
        neutral: PaletteOptions["primary"];
        icon: PaletteOptions["primary"];
    }
}

export const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#333333",
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: "#A1AE39",
            light: "#dde2b8",
            dark: "#808F0D",
            contrastText: "#FFF",
        },
        info: {
            main: "#2196F3",
        },
        error: {
            main: "#B63932",
            light: "#F5B49A",
        },
        warning: {
            main: "#E54402",
        },
        success: {
            main: "#A1AE39",
        },
        neutral: {
            main: "#E1E6E7",
            dark: "#D0D5D7",
            light: "#F5F7F7",
        },
        icon: {
            main: "#9BA5A7",
        },

        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        text: {
            primary: "#222",
            secondary: "#333",
        },
    },
});
