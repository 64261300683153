import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Redirect } from "react-router-dom";
import Copyright from "../../components/copyright";
import { useDispatch } from "react-redux";
import {
    attemptLogin,
    selectAuthenticationState,
    checkAuthenticated,
    AuthenticationState,
    selectLoginError,
    setLoginError,
} from "../../ducks/user";
import { useSelector } from "../../ducks/root-reducer";
import { strings } from "../../modules/strings/strings";
const { app, buttons } = strings;

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Login() {
    const classes = useStyles();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(checkAuthenticated());
    }, [dispatch]);

    const authState = useSelector(selectAuthenticationState);
    const errorMessage = useSelector(selectLoginError);

    if (authState === AuthenticationState.Authenticated) {
        return <Redirect to="/dashboard" />;
    }

    if (authState === AuthenticationState.Authenticating) {
        // TODO: loader?
        return null;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {app.loginScreenTitle}
                </Typography>
                <Typography style={{ color: "red", marginTop: errorMessage ? 15 : 0 }}>
                    {errorMessage}
                </Typography>
                <form
                    data-cy="login-form"
                    className={classes.form}
                    onSubmit={event => {
                        event.preventDefault();
                        const inputs = event.currentTarget.getElementsByTagName("input");
                        if (
                            // This is to keep typescript happy
                            !(inputs[0] instanceof HTMLInputElement) ||
                            !(inputs[1] instanceof HTMLInputElement)
                        ) {
                            return;
                        }
                        const email = inputs[0].value;
                        const password = inputs[1].value;

                        dispatch(attemptLogin(email, password));
                    }}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Email"
                        name="username"
                        autoComplete="username"
                        onFocus={() => {
                            dispatch(setLoginError(undefined));
                        }}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onFocus={() => {
                            dispatch(setLoginError(undefined));
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {buttons.labels.signIn}
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default Login;
