import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { AppPage } from "../../ducks/ui";
import AppLink from "../../components/app-link/app-link";

type SampleDetailDartOrderProps = {
    orders: string[] | undefined;
    backToPage: AppPage;
};

function SampleDetailDartOrders({ orders, backToPage }: SampleDetailDartOrderProps) {
    return (
        <div style={{ margin: "30px 10px", width: 450 }}>
            <h3>DArT Orders</h3>
            {orders && orders.length ? (
                <Table>
                    <TableBody>
                        {orders.map((orderId, i) => {
                            return (
                                <TableRow
                                    key={orderId}
                                    style={{
                                        backgroundColor: i % 2 === 0 ? "#F9F9F9" : "white",
                                    }}
                                >
                                    <TableCell>
                                        <AppLink
                                            to={`/dashboard/orders/${orderId}`}
                                            innerText={orderId}
                                            backToPage={backToPage}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <span>None</span>
            )}
        </div>
    );
}

export default SampleDetailDartOrders;
