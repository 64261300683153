import { PaginatedResultSet } from ".";

export enum ImportType {
    EMAIL_IMPORT = "Email Import",
    MANUAL_IMPORT = "Manual Import",
    MANUAL_REPAIR = "Manual Repair",
}

export type ImportRecordListItem = {
    importId: string;
    importType: ImportType;
    timestamp: Date;
    progress: number;
    complete: boolean;
};

export type ImportRecordListResponse = PaginatedResultSet<ImportRecordListItem>;

export type ImportRecordDetailResponse = ImportRecordListItem & {
    files: ImportFileResponse[];
};

export type ImportFileResponse = {
    fileId: string;
    sourceFile: string;
    rejections: ImportRejectionResponse[];
};

export type ImportRejectionResponse = {
    rejectionId: string;
    originalRow: string;
    error: string;
};
