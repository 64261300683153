import { parse } from "json2csv";
import { uniq } from "lodash";

export const rejectionsToCsvUrl = (rejections: string[]): string => {
    if (!rejections || !rejections.length) return "";

    try {
        const rejObj = uniq(rejections).map((x: string) => JSON.parse(x));

        const tsv = parse(rejObj, { delimiter: "\t" });
        const blob = new Blob([tsv], { type: "text/tsv;charset=utf-8;" });
        return URL.createObjectURL(blob);
    } catch {
        return "";
    }
};
