import { DetailPageCorrection } from "rbgds-shared";
import React from "react";
import InfoTable from "../../components/info-table/info-table";
import { correctionColumns } from "./detail-page-corrections-config";

type DetailPageCorrectionsProps = {
    corrections: DetailPageCorrection[] | [];
};

function DetailPageCorrections({ corrections }: DetailPageCorrectionsProps) {
    return (
        <div>
            <div style={{ marginLeft: 10, marginTop: 48, maxWidth: 750 }}>
                <h3>Corrections</h3>
            </div>
            {corrections && (
                <InfoTable
                    rows={corrections}
                    columns={correctionColumns}
                    sortableColumns={[]}
                    backToPage={"species"}
                />
            )}
        </div>
    );
}

export default DetailPageCorrections;
