import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { setSortingBy, SortDirection } from "../../ducks/constant-data";
import { useDispatch } from "react-redux";
import { ColumnKey, TableCellAlignment } from "./info-table";

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

type InfoTableHeaderCellProps = {
    isSortable: boolean;
    label: string;
    columnKey: ColumnKey;
    align: TableCellAlignment;
    sortBy?: ColumnKey;
    sortDirection?: SortDirection;
};

function InfoTableHeaderCell({
    isSortable,
    label,
    columnKey,
    align,
    sortBy,
    sortDirection,
}: InfoTableHeaderCellProps) {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <TableCell
            key={columnKey}
            align={align}
            style={{ fontWeight: "bold" }}
            sortDirection={sortBy === columnKey ? sortDirection : false}
        >
            {isSortable ? (
                <TableSortLabel
                    active={sortBy === columnKey}
                    direction={sortBy === columnKey ? sortDirection : "asc"}
                    onClick={() => {
                        // "asc" by default
                        const newSortDirection: SortDirection =
                            sortDirection && sortDirection === "asc" && columnKey === sortBy
                                ? "desc"
                                : "asc";
                        dispatch(setSortingBy(columnKey, newSortDirection));
                    }}
                >
                    {label}
                    {sortBy === columnKey ? (
                        <span className={classes.visuallyHidden}>
                            {sortDirection === "desc" ? "sorted descending" : "sorted ascending"}
                        </span>
                    ) : null}
                </TableSortLabel>
            ) : (
                <span>{label}</span>
            )}
        </TableCell>
    );
}

export default InfoTableHeaderCell;
