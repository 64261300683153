import { TableColumn } from "../../components/info-table/info-table";
import {
    tableCellTimeStamp,
    minWidthNoWrap,
    tickOnTrueValue,
} from "../../modules/format-table-cells/format-table-cells";

export const orderColumns: TableColumn[] = [
    {
        label: "Service number",
        key: "serviceNumber",
        align: "left",
        linkKey: "serviceNumber",
        path: "orders/",
    },
    {
        label: "Date sent",
        key: "dateSent",
        align: "left",
        format: tableCellTimeStamp,
    },
    {
        label: "Service",
        key: "service",
        align: "left",
    },
    {
        label: "Date received",
        key: "dateReceived",
        align: "right",
        format: tableCellTimeStamp,
    },
    {
        label: "Species",
        key: "acceptedName",
        align: "right",
        format: minWidthNoWrap,
    },
    {
        label: "N indiv",
        key: "orderSampleCount",
        align: "right",
    },
    {
        label: "Final order",
        key: "finalOrderCount",
        align: "right",
    },
    {
        label: "Analysis status",
        key: "analysis",
        align: "right",
    },
];

export const orderSubColumns: TableColumn[] = [
    {
        label: "",
        key: "acceptedName",
        align: "left",
    },
    {
        label: "",
        key: "sampleCount",
        align: "left",
    },
    {
        label: "",
        key: "isFinalOrder",
        align: "left",
        format: tickOnTrueValue,
    },
    {
        label: "",
        key: "analysisStatus",
        align: "right",
    },
];
