import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { FilterListItem } from "../filter-dropdown/filter-dropdown";
import { addEmptyFilterOption } from "../../modules/detail-page-helpers/detail-page-helpers";

type AutocompleteSelectProps = {
    handleChange: (event: any) => void;
    label: string;
    menuItems?: FilterListItem[];
    value?: string;
    addEmptyOption?: boolean;
    disabled?: boolean;
};

function AutocompleteSelect({
    handleChange,
    menuItems,
    label,
    value,
    addEmptyOption,
    disabled,
}: AutocompleteSelectProps) {
    const displayOptions = React.useMemo(() => {
        if (!menuItems) return [];
        let vals = menuItems;
        if (value && !menuItems.find(d => d.key === value)) {
            vals = [
                ...menuItems,
                {
                    key: value || "-",
                    label: value || "-",
                },
            ];
        }
        // We may want to add an empty option
        return addEmptyOption ? addEmptyFilterOption(vals, "None") : vals;
    }, [menuItems, value, addEmptyOption]);

    // Value shown should be the value corresponding to the chosen key
    const displayValue = React.useMemo(() => {
        const item = displayOptions.find(m => m.key === value);
        return item || null;
    }, [displayOptions, value]);

    return (
        <div style={{ marginBottom: 10, marginTop: 15, maxWidth: "100%", width: "100%" }}>
            <Autocomplete
                options={displayOptions}
                value={displayValue}
                onChange={(event, value) => {
                    // OK, I can see that I designed this stangely.
                    // Really this should just return the value, passed down functions expect an event
                    // So since this component is weirdly structured, we make a pretend event object
                    handleChange({ target: { value: value?.key || "" }, event: event });
                }}
                getOptionSelected={(option, value) => {
                    return option.key === value?.key;
                }}
                getOptionLabel={option => option.label}
                style={{ width: "100%", maxWidth: 700 }}
                renderInput={params => <TextField {...params} label={label} variant="outlined" />}
                disabled={disabled}
            />
        </div>
    );
}

export default AutocompleteSelect;
