import { PaginatedResultSet, DetailPageCorrection } from ".";

export enum StateSamplingStatus {
    INCOMPLETE = "Incomplete",
    NEAR_COMPLETE = "Near complete",
    COMPLETE = "Complete",
}

export enum TaxonPriority {
    HIGH = "High",
    MEDIUM = "Medium",
    LOW = "Low",
    UNDETERMINED = "Undetermined",
}

export enum TaxonType {
    PROVISIONAL = "Provisional",
    ALA = "ALA",
}

export enum TaxonRank {
    SPECIES = "species",
    GENUS = "genus",
    SUBSPECIES = "subspecies",
    VARIETY = "variety",
    FORM = "form",
}

export enum ProvisionalAcceptedStatus {
    ACCEPTED = "Accepted",
    NOT_ACCEPTED = "Not accepted",
    NOT_APPLICABLE = "n.a.",
}

export enum InfraSpecificRank {
    SUBSPECIES = "subsp.",
    VARIETAS = "var.",
    FORMA = "f.",
}

export type SamplingStatusMap = {
    notes: string;
    ACT: StateSamplingStatus;
    NSW: StateSamplingStatus;
    NT: StateSamplingStatus;
    Qld: StateSamplingStatus;
    SA: StateSamplingStatus;
    Tas: StateSamplingStatus;
    Vic: StateSamplingStatus;
    WA: StateSamplingStatus;
};

export type TaxonListItem = {
    taxonKey: string;
    acceptedName: string;
    priority: TaxonPriority;
    provisionalAcceptedStatus?: ProvisionalAcceptedStatus;
    infraSpecificRank?: InfraSpecificRank;
    samplingStatus?: SamplingStatusMap;
} & TaxonListItemCounts;
export type TaxonListItemCounts = {
    tissueSampleCount?: number;
    voucherSampleCount?: number;
    dartOrderCount?: number;
};
export type TaxonListResponse = PaginatedResultSet<TaxonListItem>;

export type TaxonDetail = {
    taxonKey: string;
    acceptedName: string;
    synonymy: string;
    plantNETurl: string;
    priority: TaxonPriority;
    notes: string;
    samplingStatus: SamplingStatusMap;
    provisionalAcceptedStatus: ProvisionalAcceptedStatus;
    corrections: DetailPageCorrection[] | [];
    genus: string;
    species: string;
    infraSpecificRank: InfraSpecificRank;
    infraSpecificEpithet: string;
    archived: boolean;
};

export type TaxonPatch = {
    priority?: TaxonPriority;
    notes?: string;
    samplingStatus?: {
        ACT?: StateSamplingStatus;
        NSW?: StateSamplingStatus;
        NT?: StateSamplingStatus;
        Qld?: StateSamplingStatus;
        SA?: StateSamplingStatus;
        Tas?: StateSamplingStatus;
        Vic?: StateSamplingStatus;
        WA?: StateSamplingStatus;
        notes?: string;
    };
};

export type TaxonPost = {
    taxonKey: string;
    acceptedName: string;
    nameCompleteFormatted?: string;
    genus?: string | null;
    species?: string | null;
    infraSpecificRank?: InfraSpecificRank | null;
    infraSpecificEpithet?: string | null;
    rank?: TaxonRank;
    type?: TaxonType;
    provisionalAcceptedStatus?: ProvisionalAcceptedStatus;
    notes?: string;
};
