import React from "react";
import { formatTzTimestamp, semicolonStringToCommaString } from "../formatters";
import { ColumnKey } from "../../components/info-table/info-table";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Check from "@material-ui/icons/Check";
import { truncateString } from "../cellValueToString";

// Format table cells

export const tableCellTimeStamp = (row: any, key: ColumnKey): JSX.Element => {
    const val = row[key];
    return <span>{formatTzTimestamp(val)}</span>;
};

export const commaString = (row: any, key: ColumnKey): JSX.Element => {
    const val = row[key] as string;
    const truncatedVal = truncateString(val, 20);
    return <span>{semicolonStringToCommaString(truncatedVal)}</span>;
};

export const statusFormat = (row: any, key: ColumnKey): JSX.Element => {
    const val = row[key];
    return <span>{val ? "Completed" : "Uploading"}</span>;
};

export const addErrorIcon = (row: any, key: ColumnKey): JSX.Element => {
    if (row.error?.error) {
        return (
            <div title={row.error.message}>
                <ErrorOutlineIcon />
            </div>
        );
    } else {
        return <span />;
    }
};

export const minWidthNoWrap = (row: any, key: ColumnKey): JSX.Element => {
    const val = row[key];
    return (
        <span style={{ whiteSpace: "nowrap", display: "inline-block", minWidth: 150 }}>{val}</span>
    );
};

export const onlyNswStatus = (row: any, key: ColumnKey): JSX.Element => {
    return <span>{row.samplingStatus?.NSW}</span>;
};

export const tickOnTrueValue = (row: any, key: ColumnKey): JSX.Element => {
    if (row[key] === true) {
        return <Check />;
    } else {
        return <span>-</span>;
    }
};
