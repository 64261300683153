import { TableColumn } from "../../components/info-table/info-table";
import {
    tableCellTimeStamp,
    commaString,
} from "../../modules/format-table-cells/format-table-cells";

export const eventColumns: TableColumn[] = [
    {
        label: "Event key",
        key: "eventKey",
        align: "left",
        path: "events/",
        linkKey: "eventKey",
    },
    { label: "Collector", key: "peoplePresent", align: "left", truncate: 50, format: commaString },
    { label: "Locality", key: "locality", align: "left", truncate: 20 },
    { label: "Collecting date", key: "eventDate", align: "right", format: tableCellTimeStamp },
    { label: "Species", key: "speciesCount", align: "right" },
    { label: "Tissues", key: "tissueSampleCount", align: "right" },
    { label: "Vouchers", key: "voucherSampleCount", align: "right" },
];
