import React from "react";
import ActionButton from "./action-button";

type ArchiveButtonProps = {
    modelBaseToArchive: "species" | "sample";
    handleArchive: () => void;
    isArchived: boolean;
    archiveError: boolean;
    cantArchive?: boolean;
};

const ArchiveButton = ({
    modelBaseToArchive,
    handleArchive,
    isArchived,
    archiveError,
    cantArchive,
}: ArchiveButtonProps) => {
    return (
        <div>
            {isArchived && (
                <p>
                    <b>This {modelBaseToArchive} is currently archived.</b>
                </p>
            )}
            <p>
                <i>
                    Archived {modelBaseToArchive} are hidden from results, but all related data is kept.
                </i>
            </p>
            <p>
                If archiving the {modelBaseToArchive}, it is helpful to write the reason in the notes
                field above.
            </p>
            <ActionButton
                label={`Archive ${modelBaseToArchive}`}
                severity="danger"
                onClick={handleArchive}
                disabled={cantArchive || isArchived}
            />
            {archiveError && (
                <p>
                    <b>ERROR:</b> There was an error archiving this {modelBaseToArchive}.
                </p>
            )}

            {modelBaseToArchive === "species" && !archiveError && cantArchive && (
                <p>
                    This species has related samples attached to it, so can't be deleted until those
                    samples are moved.
                </p>
            )}
        </div>
    );
};

export default ArchiveButton;
