import React from "react";
import { useParams } from "react-router-dom";
import { ImportRecordDetailResponse } from "rbgds-shared";
import { useDetailForm } from "../../hooks/useDetailForm";
import DetailPage from "../../components/detail-page/detail-page";
import { importForm } from "./imports-detail-config";
import { Typography } from "@material-ui/core";
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from "../../style/expansion-panel.styles";
import DetailFormTextfield from "../../components/detail-page-textfield/detail-page-textfield";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DropdownButton from "../../components/button/dropdown-button";
import { rejectionsToCsvUrl } from "./rejections-to-csv";

type RejectionFieldsProps = {
    rejections: { originalRow: string; error: string }[];
};

const RejectionFields: React.FC<RejectionFieldsProps> = React.memo(({ rejections }) => (
    <>
        {rejections.map((r, j) => (
            <DetailFormTextfield
                key={`rejection-${j}`}
                label={r.error}
                value={r.originalRow}
                isReadOnly={true}
                handleChange={e => true}
                isOneLineOnly={true}
            />
        ))}
    </>
));

function ImportsDetailPage() {
    let { id } = useParams();

    const {
        displayData,
        patchData,
        setPatchValue,
        undoUnsavedChanges,
        submit,
        errors,
        savingStatus,
    } = useDetailForm<ImportRecordDetailResponse, {}>(id, "import", "import", importForm, true);

    return (
        <div>
            {displayData && (
                <>
                    <DetailPage
                        id={id}
                        header={"Import: " + displayData.importId || ""}
                        formSections={importForm}
                        displayData={displayData}
                        patchData={patchData}
                        submit={submit}
                        setPatchValue={setPatchValue}
                        undoUnsavedChanges={undoUnsavedChanges}
                        errors={errors}
                        backToPage={"import"}
                        detailPagePath={"import"}
                        savingStatus={savingStatus}
                        isReadOnly={true}
                    />
                    {displayData.files?.filter(x => !!x.sourceFile).length > 0 && (
                        <Typography
                            component="h1"
                            variant="caption"
                            color="inherit"
                            style={{ padding: "5px 10px" }}
                        >
                            Source files
                        </Typography>
                    )}
                    {displayData.files
                        ?.filter(x => !!x.sourceFile)
                        .map((x, i) => (
                            <ExpansionPanel key={i} data-cy="source-files-errors">
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography component="h1" variant="subtitle1" color="inherit">
                                        {x.sourceFile}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{ display: "unset" }}>
                                    {x.rejections && x.rejections.length ? (
                                        <div style={{ padding: "0px 10px" }}>
                                            <DropdownButton
                                                buttonLabel={"Export"}
                                                buttonOptions={[
                                                    {
                                                        label: "TSV",
                                                        downloadHref: rejectionsToCsvUrl(
                                                            x.rejections.map(r => r.originalRow)
                                                        ),
                                                        downloadFilename: `${x.sourceFile}-rejections.tsv`,
                                                    },
                                                ]}
                                            />
                                        </div>
                                    ) : (
                                        <div style={{ padding: "0px 10px" }}>
                                            <Typography
                                                component="h3"
                                                variant="caption"
                                                color="inherit"
                                                style={{ padding: "5px 10px" }}
                                            >
                                                No errors
                                            </Typography>
                                        </div>
                                    )}
                                    <RejectionFields rejections={x.rejections} />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))}
                </>
            )}
        </div>
    );
}

export default ImportsDetailPage;
