import React from "react";
import { FilterListItem } from "../filter-dropdown/filter-dropdown";
import AutocompleteSelect from "./autocomplete-select";
import DetailFormFieldWrapper from "../detail-form/detail-form-field-wrapper";

type DetailFormFilterProps = {
    handleChange: (e: any) => void;
    label: string;
    isReadOnly: boolean;
    value: string;
    options: FilterListItem[];
    addEmptyOption?: boolean;
};

function DetailPageAutocomplete({
    handleChange,
    label,
    isReadOnly,
    value,
    options,
    addEmptyOption,
}: DetailFormFilterProps) {
    const select = (
        <AutocompleteSelect
            handleChange={handleChange}
            menuItems={options}
            label={label}
            value={value}
            addEmptyOption={addEmptyOption}
        />
    );

    return (
        <DetailFormFieldWrapper body={select} isReadOnly={isReadOnly} error={{ error: false }} />
    );
}

export default DetailPageAutocomplete;
