// Semicolon-separated string 'data structure' operations;)

// EXPOSED FUNCTIONS

export const fuzzySemicolonStringIncludes = (needle: string, haystack: string): boolean => {
    const arr = semicolonStringToArray(haystack).map(str => str.toLocaleLowerCase());
    return arr.includes(needle.toLocaleLowerCase());
};

export const toggleStringInSemicolonString = (semicolonString: string, newItem: string): string => {
    // If the string is in there, remove it
    if (fuzzySemicolonStringIncludes(newItem, semicolonString)) {
        return popStringFromSemicolonString(semicolonString, newItem);
    }
    // Else, add it
    else {
        return pushStringToSemicolonString(semicolonString, newItem);
    }
};

// OPERATIONS

// Push
// Add item to string, return new string
const pushStringToSemicolonString = (semicolonString: string, newItem: string): string => {
    return `${newItem}; ${semicolonString}`;
};

// Pop
// Remove item from string, return new string
const popStringFromSemicolonString = (semicolonString: string, itemToRemove: string): string => {
    const newItems = semicolonStringToArray(semicolonString).filter(
        item => item.toLocaleLowerCase() !== itemToRemove.toLocaleLowerCase()
    );
    return newItems.join("; ");
};

// HELPER FUNCTIONS

// Not confident to use split("; "), in case spacing is irregular
const semicolonStringToArray = (semicolonString: string | null): string[] => {
    if (!semicolonString) {
        return [];
    } else {
        return semicolonString.split(";").map(item => item.trim());
    }
};
