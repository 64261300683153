import React from "react";
import { AppPage } from "../../ducks/ui";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import MuiLink from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
    detailsLink: {
        color: theme.palette.secondary.dark,
        textDecoration: "none",
    },
}));

type AppLinkProps = {
    to: string;
    backToPage: AppPage;
    innerText: string | JSX.Element;
    external?: boolean;
    variant?: TypographyProps["variant"];
    title?: string;
};

function AppLink({ to, backToPage, innerText, external, variant, title }: AppLinkProps) {
    const classes = useStyles();

    return external ? (
        <MuiLink
            href={to}
            className={classes.detailsLink}
            variant={variant || "inherit"}
            target={"_blank"}
            rel={"noopener"}
        >
            {innerText}
        </MuiLink>
    ) : (
        <Link to={{ pathname: to, state: backToPage }} className={classes.detailsLink}>
            <Typography variant={variant || "inherit"} title={title || `Go to ${to}`}>
                <span style={{ whiteSpace: "nowrap" }}>{innerText}</span>
            </Typography>
        </Link>
    );
}

export default AppLink;
