import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { TableColumn } from "./info-table";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { AppPage } from "../../ducks/ui";
import InfoTableCell from "./info-table-cell";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    arrowIcon: {
        color: theme.palette.secondary.main,
    },
}));

type InfoTableRowProps = {
    row: any;
    columns: TableColumn[];
    rowIndex: number;
    backToPage: AppPage;
    isCollapsible?: boolean;
    subRows?: any;
    subColumns?: TableColumn[];
};

function InfoTableRow({
    row,
    columns,
    subColumns,
    rowIndex,
    subRows,
    isCollapsible,
    backToPage,
}: InfoTableRowProps) {
    const classes = useStyles();
    const theme = useTheme();

    // For collapsible rows
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow
                key={rowIndex}
                style={{
                    backgroundColor: rowIndex % 2 === 0 ? theme.palette.neutral.light : "white",
                }}
            >
                {columns.map((col, j: number) => {
                    return (
                        <InfoTableCell
                            key={j}
                            column={col}
                            backToPage={backToPage}
                            row={row}
                            align={col.align}
                        />
                    );
                })}
                {isCollapsible && (
                    <TableCell style={{ width: "30px", overflow: "hidden", padding: 12 }}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon className={classes.arrowIcon} />
                            ) : (
                                <KeyboardArrowDownIcon className={classes.arrowIcon} />
                            )}
                        </IconButton>
                    </TableCell>
                )}
            </TableRow>
            {/* Sub-rows */}
            {subRows && isCollapsible && open && (
                <>
                    {subRows.map((subRow: any, i: number) => {
                        return (
                            <TableRow key={`subrow-${i}`}>
                                {columns.map((col, i) => {
                                    // This is a little hacky.
                                    // We're going to map over the main cols, but render the sub-cols
                                    const offset = subColumns?.length
                                        ? columns.length - subColumns.length
                                        : 0;

                                    if (i >= offset && subColumns) {
                                        return (
                                            <InfoTableCell
                                                key={i}
                                                column={subColumns[i - offset]}
                                                backToPage={backToPage}
                                                row={subRow}
                                                align={col.align}
                                            />
                                        );
                                    } else {
                                        return <TableCell key={i} />;
                                    }
                                })}
                                <TableCell />
                            </TableRow>
                        );
                    })}
                </>
            )}
        </>
    );
}

export default InfoTableRow;
