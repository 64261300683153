import React, { useEffect, useMemo } from "react";
import TaxonMap from "../../components/taxon-map/taxon-map";
import TableHeader from "../../components/table-header/table-header";
import { useSelector } from "../../ducks/root-reducer";
import { useLocation } from "react-router";
import { SampleListQuery } from "../../ducks/samples";
import { useDispatch } from "react-redux";
import { parseUrlParams, stringifyUrlParams } from "../../modules/query-helpers";
import LoadingOverlay from "../../components/loading-overlay/loading-overlay";
import {
    searchMapTaxa,
    selectIsLoading,
    selectSearchResults,
    setActiveSample,
} from "../../ducks/map";
import { BASE_API_URL } from "../../modules/api";

function MapPage() {
    const location = useLocation();
    const dispatch = useDispatch();
    const searchResults = useSelector(selectSearchResults);
    const isLoading = useSelector(selectIsLoading);

    const sampleListQuery: SampleListQuery = useMemo(() => {
        return parseUrlParams(location.search.slice(1));
    }, [location.search]);

    useEffect(() => {
        dispatch(searchMapTaxa(sampleListQuery));
        dispatch(setActiveSample(undefined));
    }, [dispatch, sampleListQuery]);

    const downloadLink: string = useMemo(() => {
        const { page, ...paramsWithoutPage } = sampleListQuery;
        const stringParams = paramsWithoutPage ? `?${stringifyUrlParams(paramsWithoutPage)}` : "";

        return `${BASE_API_URL}/map.kml${stringParams}`;
    }, [sampleListQuery]);

    return (
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
            {isLoading && <LoadingOverlay />}
            <TableHeader
                title="Map"
                downloadOptions={[
                    {
                        label: "KML",
                        downloadHref: downloadLink,
                    },
                ]}
            />
            <TaxonMap taxa={searchResults} />
        </div>
    );
}

export default MapPage;
