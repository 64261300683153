import { DetailPageSection } from "../../types/details";
import {
    flowerTypes,
    fruitTypes,
    sourcesOfCoordinates,
    tissueSampleTypes,
    hybridStatuses,
} from "../../modules/listItemsFromEnums";
import { checkValidLatLongAsync } from "../../modules/validation/checkValidCoordinates";
import { checkFieldNotEmptyBlocksSaving } from "../../modules/validation/checkFieldNotEmpty";
import { formatTzTimestamp } from "../../modules/formatters";
import { HerbariumAutocompleteRow, SampleListItemType, TissueSampleType } from "rbgds-shared";
import { api } from "../../modules/api";
import { parseEventAutocompleteResponse } from "../../modules/detail-page-helpers/detail-page-helpers";

// SAMPLE TYPES
// export type SampleDetailBase = ObservationDetailBase & {
//     readonly sampleDate: string | null;
//     boxNumber: string | null;
//     bundleNumber: string | null;
// };

// export type TissueSampleDetail = SampleDetailBase & {
//     readonly NSWnumber: string;
//     readonly type: SampleListItemType.Tissue;
//     readonly dartOrders: string[];
// };

// export type VoucherSampleDetail = SampleDetailBase & {
//     readonly NSWnumber: string;
//     readonly type: SampleListItemType.Voucher;
// };

// export type IncidentalObservationDetail = ObservationDetailBase & {
//     readonly observationId: string;
//     readonly type: SampleListItemType.Voucher;
//     readonly observationDate: string | null;
// };

export const collectionInfo: DetailPageSection = {
    sectionTitle: "Collection information",
    fields: [
        { label: "NSW number", key: "NSWnumber", isReadOnly: true, inputType: "textfield" },
        {
            label: "Species",
            key: "acceptedName",
            isReadOnly: true,
            inputType: "textfield",
        },
        {
            label: "Linked voucher sample",
            key: "linkedVoucherSample",
            isReadOnly: true,
            inputType: "link",
            link: { internalLinkPath: "/dashboard/samples/voucher/" },
            showCondition: e => e.type === SampleListItemType.Tissue,
        },
        {
            label: "Linked tissue sample",
            key: "linkedTissueSample",
            isReadOnly: true,
            inputType: "link",
            link: { internalLinkPath: "/dashboard/samples/tissue/" },
            showCondition: e => e.type === SampleListItemType.Voucher,
        },
        {
            label: "Link to event",
            key: "eventKey",
            isReadOnly: true,
            inputType: "link",
            link: { internalLinkPath: "/dashboard/events/" },
        },
        {
            label: "Event key",
            key: "eventKey",
            isReadOnly: false,
            inputType: "autocomplete-async",
            getOptions: async query => {
                if (query.length < 2) return [];

                const response = await api(`sampling-event/autocomplete/${query}`);
                const responseJson = await response.json();
                return parseEventAutocompleteResponse(responseJson);
            },
        },
        {
            label: "Type",
            key: "type",
            isReadOnly: true,
            inputType: "textfield",
        },
        {
            label: "Tissue sample type",
            key: "tissueSampleType",
            isReadOnly: false,
            inputType: "autocomplete",
            options: tissueSampleTypes,
            showCondition: e => e.type === SampleListItemType.Tissue,
        },
        {
            label: "Collecting date",
            key: "sampleDate",
            isReadOnly: true,
            inputType: "textfield",
            formatReadOnlyValue: formatTzTimestamp,
        },
        { label: "Collector", key: "peoplePresent", isReadOnly: true, inputType: "textfield" },
        { label: "Projects", key: "associatedProjects", isReadOnly: true, inputType: "textfield" },
        { label: "Locality", key: "locality", isReadOnly: true, inputType: "textfield" },
        {
            label: "Field species name",
            getLabel: e =>
                e.tissueSampleType === TissueSampleType.DESTRUCTIVE
                    ? "Species name"
                    : "Field species name",
            key: "fieldSpeciesName",
            isReadOnly: true,
            inputType: "textfield",
        },
        {
            label: "Infraspecific rank",
            key: "infraSpecificRank",
            isReadOnly: true,
            inputType: "textfield",
        },
        {
            label: "Infraspecific name",
            key: "infraSpecificName",
            isReadOnly: true,
            inputType: "textfield",
        },
        {
            label: "Field Number",
            key: "fieldNumber",
            isReadOnly: true,
            inputType: "textfield",
        },
        {
            label: "Hybrid status",
            key: "hybridStatus",
            isReadOnly: false,
            options: hybridStatuses,
            inputType: "filter",
        },
        {
            label: "Hybrid status notes",
            key: "hybridStatusNotes",
            isReadOnly: false,
            inputType: "textfield",
        },
        {
            label: "General Notes",
            key: "generalNotes",
            isReadOnly: false,
            inputType: "textfield",
        },
        {
            label: "Collection Notes",
            key: "collectionNotes",
            isReadOnly: false,
            inputType: "textfield",
        },
        {
            label: "Site Notes",
            key: "siteNotes",
            isReadOnly: true,
            inputType: "textfield",
        },
    ],
};

export const incidentalCollectionInfo: DetailPageSection = {
    sectionTitle: "Collection information",
    fields: [
        {
            label: "Observation id",
            key: "observationId",
            isReadOnly: true,
            inputType: "textfield",
        },
        {
            label: "Observation date",
            key: "observationDate",
            isReadOnly: true,
            inputType: "textfield",
            formatReadOnlyValue: formatTzTimestamp,
        },
        { label: "Species", key: "fieldSpeciesName", isReadOnly: true, inputType: "textfield" },
        {
            label: "Event key",
            key: "eventKey",
            isReadOnly: true,
            inputType: "link",
            link: { internalLinkPath: "/dashboard/events/" },
        },
        { label: "Type", key: "type", isReadOnly: true, inputType: "textfield" },
        {
            label: "Collector",
            key: "peoplePresent",
            isReadOnly: true,
            inputType: "textfield",
        },
        { label: "Projects", key: "associatedProjects", isReadOnly: true, inputType: "textfield" },
        { label: "Locality", key: "locality", isReadOnly: true, inputType: "textfield" },
        {
            label: "Field species name",
            key: "fieldSpeciesName",
            isReadOnly: true,
            inputType: "textfield",
        },
        {
            label: "Hybrid status",
            key: "hybridStatus",
            isReadOnly: false,
            options: hybridStatuses,
            inputType: "filter",
        },
        {
            label: "Hybrid status notes",
            key: "fieldNumber",
            isReadOnly: false,
            inputType: "textfield",
        },
    ],
};

export const coordinates: DetailPageSection = {
    sectionTitle: "Coordinates",
    fields: [
        {
            label: "",
            key: "sampleLocation",
            inputType: "coordinates",
            isReadOnly: false,
            validate: checkValidLatLongAsync,
        },
        {
            label: "Source of coordinates",
            key: "sourceOfCoordinates",
            isReadOnly: false,
            inputType: "autocomplete",
            options: sourcesOfCoordinates,
        },
    ],
};

export const identification: DetailPageSection = {
    sectionTitle: "Re-identification",
    sectionNotes:
        "The history of all re-identifications will be saved. If you don't make changes to this section, it will not be submitted with the form.",
    fields: [
        {
            label: "Species name",
            key: "taxonKey",
            isReadOnly: false,
            optionsKey: "speciesWithGenera",
            inputType: "autocomplete",
        },
        {
            label: "Date of identification",
            key: "dateIdentified",
            isReadOnly: false,
            inputType: "datepicker",
        },
        {
            label: "Identification remarks",
            key: "identificationRemarks",
            isReadOnly: false,
            inputType: "textfield",
            validate: checkFieldNotEmptyBlocksSaving,
        },
        {
            label: "Identified by",
            key: "identifiedBy",
            isReadOnly: false,
            inputType: "textfield",
            validate: checkFieldNotEmptyBlocksSaving,
        },
    ],
};

export const storage: DetailPageSection = {
    sectionTitle: "Storage",
    fields: [
        { label: "Box number", key: "boxNumber", isReadOnly: false, inputType: "textfield" },
        {
            label: "Bundle number",
            key: "bundleNumber",
            isReadOnly: false,
            inputType: "textfield",
        },
    ],
};

export const populationInformation: DetailPageSection = {
    sectionTitle: "Population information",
    fields: [
        {
            label: "",
            inputType: "",
            key: "phenology",
            isReadOnly: true,
            subFields: [
                {
                    label: "Fruit",
                    key: "fruit",
                    isReadOnly: true,
                    options: fruitTypes,
                    inputType: "checkboxes",
                },
                {
                    label: "Flowers",
                    key: "flowers",
                    isReadOnly: true,
                    options: flowerTypes,
                    inputType: "checkboxes",
                },
                {
                    label: "Reproductive state",
                    key: "reproductiveState",
                    isReadOnly: true,
                    inputType: "textfield",
                },
            ],
        },
        {
            label: "",
            inputType: "",
            key: "populationInfo",
            isReadOnly: true,
            subFields: [
                {
                    label: "Other members of the same species within 10 metres",
                    key: "plants10m",
                    isReadOnly: true,
                    inputType: "textfield",
                },
                {
                    label: "Adults present",
                    key: "adultsPresent",
                    isReadOnly: true,
                    inputType: "textfield",
                },
                {
                    label: "Juveniles present",
                    key: "juvenilesPresent",
                    isReadOnly: true,
                    inputType: "textfield",
                },
                {
                    label: "Species ID confidence",
                    key: "speciesIDConfidence",
                    isReadOnly: true,
                    inputType: "textfield",
                },
                {
                    label: "Species ID notes",
                    key: "speciesIDNotes",
                    isReadOnly: true,
                    inputType: "textfield",
                },
                {
                    label: "Native status",
                    key: "nativeStatus",
                    isReadOnly: true,
                    inputType: "textfield",
                },
                {
                    label: "Notes on population",
                    key: "notes",
                    isReadOnly: true,
                    inputType: "textfield",
                },
            ],
        },
    ],
};

export const destructiveSamplingFormSection: DetailPageSection = {
    sectionTitle: "Destructive sampling information",
    showCondition: e =>
        e.type === SampleListItemType.Tissue && e.tissueSampleType === TissueSampleType.DESTRUCTIVE,
    fields: [
        {
            label: "herbariumName",
            key: "herbariumName",
            isReadOnly: false,
            inputType: "autocomplete-async",
            getOptions: async query => {
                if (query.length === 0) {
                    return [];
                }
                const res = await api(`herbarium/autocomplete/${query}`);
                const opts = await res.json();
                return opts.map((raw: HerbariumAutocompleteRow) => ({
                    key: raw.herbariumID,
                    label: `[${raw.herbariumID}] - ${raw.institutionName}${
                        raw.collectionCode ? " (" + raw.collectionCode + ")" : ""
                    }`,
                }));
            },
        },
        {
            label: "Herbarium Specimen IRN",
            key: "herbariumSpecimenIRN",
            isReadOnly: false,
            inputType: "textfield",
        },
        {
            label: "Herbarium Specimen Collector",
            key: "herbariumSpecimenCollector",
            isReadOnly: false,
            inputType: "textfield",
        },
        {
            label: "Destructively Sampled By",
            key: "destructiveSamplingBy",
            isReadOnly: false,
            inputType: "textfield",
        },
        {
            label: "Herbarium Collection Date",
            key: "herbariumCollectionDate",
            isReadOnly: false,
            inputType: "datepicker",
        },
        {
            label: "Destructive Sampling Date",
            key: "destructiveSamplingDate",
            isReadOnly: false,
            inputType: "datepicker",
        },
        {
            label: "Destructively Sampling Site",
            key: "destructiveSamplingSiteName",
            isReadOnly: false,
            inputType: "textfield",
        },
    ],
};
