import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: "absolute",
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 4,
        },
    })
);

type AddNewModalProps = {
    isOpen: boolean;
    handleClose: () => void;
    body: JSX.Element;
};

function AddNewModal({ isOpen, handleClose, body }: AddNewModalProps) {
    const classes = useStyles();

    return (
        <div>
            <Modal open={isOpen} onClose={handleClose}>
                <div className={classes.paper} style={{ borderRadius: 4 }}>
                    {body}
                </div>
            </Modal>
        </div>
    );
}

export default AddNewModal;
