export function numberToLetter(number: number): string {
    if (!number && !(number === 0)) {
        throw new Error(
            "numberToLetter received a null number. Check that samples do not have null row/column numbers."
        );
    }

    if (number >= 0 && number <= 25) {
        return String.fromCharCode(number + 65);
    } else {
        throw new Error("Numbers to convert to letters must be in the range 0-24");
    }
}

export function letterToNumber(letter: string): number {
    if (!letter) {
        throw new Error(
            "letterToNumber received a null letter. Check that samples do not have null row/column numbers."
        );
    } else if (letter.length > 1) {
        throw new Error("Row code string is too long");
    } else {
        const charCode = letter.charCodeAt(0) - 65;
        if (charCode >= 0 && charCode <= 25) {
            return charCode;
        } else {
            throw new Error("Letters to convert to numbers must be in the range A-Z");
        }
    }
}
