import React from "react";
import NewItemForm from "../../components/add-new-modal/new-item-form";
import { useNewItemForm } from "../../hooks/useNewItemForm";
import { DartOrderPost } from "rbgds-shared";
import { newOrderFields } from "./new-item-field-config";
import { strings } from "../../modules/strings/strings";
const { addItemModalStrings } = strings;

type NewOrderFormProps = {
    handleClose: () => void;
};

function NewOrderForm({ handleClose }: NewOrderFormProps) {
    const { submit, setPostValue, displayData, errors, savingStatus } = useNewItemForm<
        DartOrderPost
    >("dart-order", newOrderFields, []);

    return (
        <NewItemForm
            onSubmit={submit}
            displayData={displayData}
            handleClose={handleClose}
            heading={addItemModalStrings.addOrder}
            subheading={addItemModalStrings.addOrderSubheading}
            formFields={newOrderFields}
            setPostValue={setPostValue}
            errors={errors}
            savingStatus={savingStatus}
            newItemPath={"orders"}
            backToPage="orders"
        />
    );
}

export default NewOrderForm;
