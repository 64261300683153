import { TableColumn } from "../../components/info-table/info-table";
import { onlyNswStatus } from "../../modules/format-table-cells/format-table-cells";

export const speciesColumns: TableColumn[] = [
    {
        label: "Species",
        key: "acceptedName",
        align: "left",
        linkKey: "taxonKey",
        path: "species/",
    },
    { label: "Type", key: "type", align: "right" },
    { label: "Provisional Status", key: "provisionalAcceptedStatus", align: "right" },
    { label: "Tissues", key: "tissueSampleCount", align: "right" },
    { label: "Vouchers", key: "voucherSampleCount", align: "right" },
    { label: "No. sent to DArT", key: "dartOrderCount", align: "right" },
    { label: "Priority", key: "priority", align: "left" },
    { label: "NSW Status", key: "samplingStatus", align: "left", format: onlyNswStatus },
];
