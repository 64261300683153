import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./root-reducer";
import { AppThunk } from "./store";
import qs from "qs";
import { history } from "../pages/root";
import { constantDataSlice } from "./constant-data";
import { mapItemTypes } from "../modules/listItemsFromEnums";

const DEFAULT_GENUS = "Acacia";
const DEFAULT_SPECIES = "alaticaulis";

export type AppPage =
    | "samples"
    | "map"
    | "events"
    | "species"
    | "orders"
    | "sample details"
    | "event details"
    | "species details"
    | "order details"
    | "import";

export type UiState = {
    activePage: AppPage;
    drawerIsOpen: boolean;
    currentSearchQuery: string;
};

const initialState: UiState = {
    activePage: "samples",
    drawerIsOpen: true,
    currentSearchQuery: "",
};

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setActivePage(state, action: PayloadAction<AppPage>) {
            state.activePage = action.payload;
        },
        setDrawerIsOpen(state, action: PayloadAction<boolean>) {
            state.drawerIsOpen = action.payload;
        },
        setCurrentSearchQuery(state, action: PayloadAction<string>) {
            state.currentSearchQuery = action.payload;
        },
    },
});

export default uiSlice;

export const { setDrawerIsOpen, setCurrentSearchQuery } = uiSlice.actions;

// ----------------------------------------------------------------------------
// Selectors
export const selectActivePage = (state: RootState): AppPage => {
    return state?.ui.activePage;
};

export const selectDrawerIsOpen = (state: RootState): boolean => {
    return state?.ui.drawerIsOpen;
};

export const selectCurrentSearchQuery = (state: RootState): string => {
    return state?.ui.currentSearchQuery;
};

// ----------------------------------------------------------------------------
// Thunks

export function setActivePage(page: AppPage): AppThunk {
    return async dispatch => {
        // Update URL
        if (page === "map") {
            history.push({
                pathname: "map",
                search: qs.stringify({
                    // We want to exclude ALA herbarium & NSW VIS on route load
                    type: mapItemTypes.slice(0, 4).join(","),
                    genus: DEFAULT_GENUS,
                    species: DEFAULT_SPECIES,
                }),
            });
            await dispatch(constantDataSlice.actions.setActiveGenus(DEFAULT_GENUS));
            await dispatch(constantDataSlice.actions.setActiveSpecies(DEFAULT_SPECIES));
        } else {
            history.push(page);
        }

        // Update redux
        dispatch(uiSlice.actions.setActivePage(page));
    };
}
