import React from "react";
import { FilterListItem } from "../filter-dropdown/filter-dropdown";
import AutocompleteSelect from "../detail-page-autocomplete/autocomplete-select";

type OrdersMenuProps = {
    activeGenus?: string;
    activeSpecies?: string;
    activeProject?: string;
    handleGenusChange: (event: any) => void;
    handleSpeciesChange: (event: any) => void;
    handleProjectChange: (event: any) => void;
    genera?: FilterListItem[];
    species?: FilterListItem[];
    projects?: FilterListItem[];
};

function OrdersMenu({
    activeGenus,
    activeSpecies,
    activeProject,
    handleGenusChange,
    handleProjectChange,
    handleSpeciesChange,
    genera,
    species,
    projects,
}: OrdersMenuProps) {
    return (
        <div>
            <AutocompleteSelect
                label="Project"
                value={activeProject || ""}
                handleChange={handleProjectChange}
                menuItems={projects}
            />
            <AutocompleteSelect
                label="Genus"
                value={activeGenus || ""}
                handleChange={handleGenusChange}
                menuItems={genera}
            />
            {activeGenus && (
                <AutocompleteSelect
                    label="Species"
                    value={activeSpecies || ""}
                    handleChange={handleSpeciesChange}
                    menuItems={species}
                />
            )}
        </div>
    );
}

export default OrdersMenu;
