import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "../../ducks/root-reducer";
import { useLocation } from "react-router";
import {
    DartOrderQuery,
    selectOrderPagination,
    selectOrderResults,
    selectOrdersIsLoading,
    searchDartOrders,
} from "../../ducks/orders";
import { setQuery, SortDirection } from "../../ducks/constant-data";
import { useDispatch } from "react-redux";
import { ColumnKey } from "../../components/info-table/info-table";
import InfoTable from "../../components/info-table/info-table";
import { parseUrlParams, stringifyUrlParams } from "../../modules/query-helpers";
import LoadingOverlay from "../../components/loading-overlay/loading-overlay";
import TableHeader from "../../components/table-header/table-header";
import SearchBar from "../../components/search-bar/search-bar";
import { BASE_API_URL } from "../../modules/api";
import AddNewModal from "../../components/add-new-modal/add-new-modal";
import NewOrderForm from "../../components/add-new-modal/new-order-form";
import { sortableColumns } from "../../types/sortableColumns";
import { orderColumns, orderSubColumns } from "./orders-page-config";

function OrdersPage() {
    const location = useLocation();
    const dispatch = useDispatch();
    const orderResults = useSelector(selectOrderResults);
    const pagination = useSelector(selectOrderPagination);
    const isLoading = useSelector(selectOrdersIsLoading);
    const [newModalOpen, setNewModalOpen] = useState<boolean>(false);

    const dartOrderQuery: DartOrderQuery = useMemo(() => {
        return parseUrlParams(location.search.slice(1));
    }, [location.search]);

    useEffect(() => {
        dispatch(searchDartOrders(dartOrderQuery));
    }, [dispatch, dartOrderQuery]);

    const downloadLink: string = useMemo(() => {
        const { page, ...paramsWithoutPage } = dartOrderQuery;
        const stringParams = paramsWithoutPage ? `?${stringifyUrlParams(paramsWithoutPage)}` : "";
        return `${BASE_API_URL}/dart-order.csv${stringParams}`;
    }, [dartOrderQuery]);

    const { sortBy, sortDirection, query } = dartOrderQuery;

    return (
        <div style={{ minHeight: 500, position: "relative", height: "100%", width: "100%" }}>
            {isLoading && <LoadingOverlay />}
            <TableHeader
                title="DArT Orders"
                downloadOptions={[
                    {
                        label: "CSV",
                        downloadHref: downloadLink,
                    },
                ]}
                createNewOptions={[
                    {
                        label: "New Order",
                        onClick: () => {
                            setNewModalOpen(true);
                        },
                    },
                ]}
            />
            <SearchBar
                placeHolder={"Service number"}
                query={query}
                onKeyUp={newQuery => {
                    dispatch(setQuery(newQuery));
                }}
            />
            {orderResults && (
                <InfoTable
                    rows={orderResults}
                    columns={orderColumns}
                    sortableColumns={sortableColumns.orders}
                    subColumns={orderSubColumns}
                    pagination={pagination}
                    sortBy={sortBy as ColumnKey}
                    sortDirection={sortDirection as SortDirection}
                    backToPage={"orders"}
                />
            )}
            <AddNewModal
                isOpen={newModalOpen}
                handleClose={() => {
                    setNewModalOpen(false);
                }}
                body={
                    <NewOrderForm
                        handleClose={() => {
                            setNewModalOpen(false);
                            dispatch(searchDartOrders(dartOrderQuery));
                        }}
                    />
                }
            />
        </div>
    );
}

export default OrdersPage;
