import { combineReducers, AnyAction } from "@reduxjs/toolkit";
import userSlice from "./user";
import { useSelector as useReduxSelector, TypedUseSelectorHook } from "react-redux";
import { UserState } from "./user";
import uiSlice, { UiState } from "./ui";
import sampleSlice, { SampleListState } from "./samples";
import taxonSlice, { TaxonState } from "./species";
import eventSlice, { SamplingEventListState } from "./events";
import mapSlice, { MapState } from "./map";
import dartOrderSlice, { DartOrderState } from "./orders";
import constantDataSlice, { ConstantDataState } from "./constant-data";
import importsSlice, { ImportRecordListState } from "./imports";

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

type GlobalState =
    | {
          user: UserState;
          ui: UiState;
          samples: SampleListState;
          taxon: TaxonState;
          events: SamplingEventListState;
          map: MapState;
          orders: DartOrderState;
          imports: ImportRecordListState;
          constantData: ConstantDataState;
      }
    | undefined;

const appReducer = combineReducers({
    user: userSlice.reducer,
    ui: uiSlice.reducer,
    samples: sampleSlice.reducer,
    taxon: taxonSlice.reducer,
    events: eventSlice.reducer,
    map: mapSlice.reducer,
    orders: dartOrderSlice.reducer,
    imports: importsSlice.reducer,
    constantData: constantDataSlice.reducer,
});

export const rootReducer = (state: GlobalState, action: AnyAction) => {
    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
