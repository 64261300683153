import { NewItemModalField, NewItemKey } from "../../types/post-new";
import { DataError, SavingStatus } from "../../types/form-types";
import { DetailPagePath } from "../../modules/api";

export const getFieldByKey = (
    key: NewItemKey,
    fields: NewItemModalField[]
): NewItemModalField | undefined => {
    return fields.find(f => f.key === key);
};

export const allFieldsAreFilled = (fields: NewItemModalField[], displayData: any): boolean => {
    const hasEmptyField = fields
        .filter(f => !f.optional)
        .reduce((acc, curr) => {
            if (!displayData[curr.key] && !(displayData[curr.key] === 0)) {
                return true;
            } else {
                return acc;
            }
        }, false);
    return !hasEmptyField;
};

export const formHasErrors = (errors: Map<NewItemKey, DataError>): boolean => {
    let hasError = false;
    errors.forEach(e => {
        if (e.error) {
            hasError = true;
        }
    });
    return hasError;
};

export const getIdFromDisplayData = (
    newItemPath: DetailPagePath,
    displayData: any,
    status?: SavingStatus
): string => {
    switch (newItemPath) {
        case "samples/tissue":
            return displayData["NSWnumber"];
        case "samples/voucher":
            return displayData["NSWnumber"];
        case "samples/incidental-observation":
            return displayData["observationId"];
        case "events":
            return displayData["eventKey"];
        case "orders":
            return displayData["serviceNumber"];
        case "species":
            return status?.idStatus?.newId || "";
        default:
            return "";
    }
};
