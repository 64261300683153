import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    withStyles,
    ListItemIcon as MuiListItemIcon,
} from "@material-ui/core";

export const ExpansionPanel = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(Accordion);

export const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
        maxWidth: "100%",
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(AccordionSummary);

export const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: "block",
    },
}))(AccordionDetails);

export const ListItemIcon = withStyles(theme => ({}))(MuiListItemIcon);
