export const cellValueToString = (
    input: string | number | null,
    truncateLength: number | undefined
): string => {
    if (!input && input !== 0) {
        return "-";
    } else if (!truncateLength) {
        return input.toString();
    } else {
        return truncateString(input.toString(), truncateLength);
    }
};

export const truncateString = (str: string | null, length: number): string => {
    if (!str) {
        return "";
    } else {
        return str.length <= length ? str : `${str.substring(0, length)}...`;
    }
};
