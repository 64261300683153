export const capitalize = (str: String) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const lPad = (str: string, digits: number, fill = "0") => {
    let out = str;
    while (out.length < digits) {
        out = `${fill}${out}`;
    }
    return out;
};

export const formatTzTimestamp = (tzTimestamp: string | null) => {
    if (!tzTimestamp) {
        return "-";
    }
    const d = new Date(Date.parse(tzTimestamp));
    if (isNaN(d.getFullYear())) {
        return "-";
    }
    // Another option with less power but less hacking would be:
    // d.toLocaleDateString("en-AU", { day: "numeric", month: "numeric", year: "numeric" });
    const date = lPad(String(d.getDate()), 2);
    const month = lPad(String(d.getMonth() + 1), 2);
    return `${date}/${month}/${`${d.getFullYear()}`.substring(2, 4)}`;
};

export const formatStatus = (progress: string | null) => {
    return parseInt(progress || "0") === 100 ? "Completed" : `Uploading... ${progress}%`;
};

export const semicolonStringToCommaString = (semiColonString: string | null): string => {
    if (!semiColonString) {
        return "-";
    } else {
        return semiColonString
            .split(";")
            .map(word => word.trim())
            .join(", ");
    }
};

// Use the type of something to generate a url path
// e.g. "Tissue (Sent to DArT)" -> tissue/
// or Incidental Observation -> incidental-observation/
export const formatKeyForUrl = (keyString: string) => {
    return (
        keyString
            .replace(" (No DaRT)", "")
            .replace(" (Sent to DArT)", "")
            .replace(" ", "-")
            .toLocaleLowerCase() + "/"
    );
};
