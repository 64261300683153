import { useState, useEffect, useRef } from "react";
import React from "react";
import ReactMapGL, { NavigationControl, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import useComponentSize from "@rehooks/component-size";
import { setActiveSample, selectActiveSample } from "../../ducks/map";
import { useDispatch } from "react-redux";
import { useSelector } from "../../ducks/root-reducer";
import InfoPopup from "../info-popup/info-popup";
import WebMercatorViewport from "@math.gl/web-mercator";
import { MapItem, MapItemType } from "rbgds-shared";
import RoomIcon from "@material-ui/icons/Room";

// Map types
type Bounds = [[number, number], [number, number]];
type TaxonMapProps = {
    taxa: MapItem[] | undefined;
};
type Viewport = {
    latitude: number;
    longitude: number;
    zoom: number;
};

// Map constants
const NSW_BBOX: Bounds = [
    [140.49927903600008, -37.90507657099996],
    [153.43883173500005, -27.457020554999974],
];
const NSW_VIEWPORT: Viewport = {
    latitude: -32.83449266565529,
    longitude: 146.96905538550004,
    zoom: 5.054065966482127,
};

const markerColors = ["#9C31CC", "#CC8733", "#B63932", "#969696", "#2BA520", "#3081CA"];
export const markerColorsMap: any = Object.entries(MapItemType).reduce((acc, [key, value], i) => {
    return { ...acc, [value]: markerColors[i % markerColors.length] };
}, {});

function TaxonMap({ taxa }: TaxonMapProps) {
    const dispatch = useDispatch();
    const activeSample = useSelector(selectActiveSample);
    let mapContainerRef = useRef(null);
    let { width, height } = useComponentSize(mapContainerRef);

    // Viewport
    const [viewport, setViewport] = useState<Viewport>(NSW_VIEWPORT);

    // Automatically keep NSW viewport if the map resizes
    useEffect(() => {
        if (width > 0) {
            const newViewport = new WebMercatorViewport({
                width: width,
                height: height,
            }).fitBounds(NSW_BBOX);
            const { latitude, longitude, zoom } = newViewport;
            setViewport({ latitude: latitude, longitude: longitude, zoom: zoom });
        }
    }, [width, height]);

    return (
        <div
            id="rbg-maparea"
            style={{
                width: "100%",
                minHeight: "500px",
                height: "calc(100% - 80px)",
            }}
            ref={mapContainerRef}
        >
            <ReactMapGL
                latitude={viewport.latitude}
                longitude={viewport.longitude}
                zoom={viewport.zoom}
                width={"100%"}
                height={"100%"}
                onViewportChange={(newViewport: { latitude: any; longitude: any; zoom: any }) => {
                    const { latitude, longitude, zoom } = newViewport;
                    setViewport({ latitude: latitude, longitude: longitude, zoom: zoom });
                }}
                mapStyle={"mapbox://styles/smallmultiples/ckbppe44e107y1jmnn0hw561e"}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            >
                {taxa &&
                    taxa
                        .filter(
                            t =>
                                t.sampleLocation?.coordinates[0] && t.sampleLocation?.coordinates[1]
                        )
                        .map(t => {
                            // if (!t.sampleLocation) {
                            //     return null;
                            // }
                            return (
                                <Marker
                                    key={"id" in t ? t.id : t.siteKey}
                                    latitude={t.sampleLocation.coordinates[1]}
                                    longitude={t.sampleLocation.coordinates[0]}
                                >
                                    <RoomIcon
                                        style={{
                                            color: markerColorsMap[t.type],
                                            transform: `translate(-50%, -100%)`,
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            dispatch(setActiveSample(t));
                                        }}
                                    />
                                </Marker>
                            );
                        })}
                {activeSample && (
                    <InfoPopup
                        coordinates={activeSample.sampleLocation.coordinates}
                        sample={activeSample}
                        onClose={() => {
                            dispatch(setActiveSample(undefined));
                        }}
                    />
                )}
                <div
                    style={{
                        width: "31px",
                        position: "absolute",
                        top: "26px",
                        right: "20px",
                    }}
                >
                    <NavigationControl
                        showCompass={false}
                        onViewportChange={(viewport: {
                            latitude: any;
                            longitude: any;
                            zoom: any;
                        }) => {
                            const { latitude, longitude, zoom } = viewport;
                            setViewport({
                                latitude: latitude,
                                longitude: longitude,
                                zoom: zoom,
                            });
                        }}
                    />
                </div>
            </ReactMapGL>
        </div>
    );
}

export default TaxonMap;
