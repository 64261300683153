import React from "react";
import Button from "@material-ui/core/Button";
import SaveButton from "../button/save-button";
import { useTheme } from "@material-ui/core/styles";
import { strings } from "../../modules/strings/strings";

const { buttons } = strings;

type DetailPageHeaderProps = {
    headerTitle?: string;
    onSaveForm: () => void;
    onCancel: () => void;
    hasChanges?: boolean;
    savingBlocked?: boolean;
    savingInProgress: boolean;
    isReadOnly?: boolean;
};

function DetailPageHeader({
    headerTitle,
    onSaveForm,
    onCancel,
    hasChanges,
    savingBlocked,
    savingInProgress,
    isReadOnly,
}: DetailPageHeaderProps) {
    const theme = useTheme();
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 10px",
            }}
        >
            <h2>{headerTitle}</h2>

            {!isReadOnly && (
                <div>
                    <Button
                        variant="contained"
                        style={{
                            height: 40,
                            backgroundColor: "white",
                            color: theme.palette.text.secondary,
                            cursor: hasChanges ? "pointer" : "not-allowed",
                            textTransform: "uppercase",
                        }}
                        onClick={() => {
                            hasChanges && onCancel();
                        }}
                        title={buttons.titles.revertChanges}
                    >
                        {buttons.labels.revertChanges}
                    </Button>
                    <SaveButton
                        label="Save"
                        onSave={onSaveForm}
                        savingBlocked={!hasChanges || savingBlocked}
                        savingInProgress={savingInProgress}
                        onBlockedSave={() => {}}
                        title={savingBlocked ? buttons.titles.savingBlocked : buttons.titles.save}
                    />
                </div>
            )}
        </div>
    );
}

export default DetailPageHeader;
