import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";

type SaveButtonProps = {
    label: string;
    onSave: () => void;
    onBlockedSave: () => void;
    savingInProgress: boolean;
    savingBlocked?: boolean;
    title?: string;
};

function SaveButton({
    label,
    onSave,
    onBlockedSave,
    savingBlocked,
    savingInProgress,
    title,
}: SaveButtonProps) {
    const theme = useTheme();
    return (
        <Button
            variant="contained"
            title={title || ""}
            style={{
                height: 40,
                backgroundColor: !savingBlocked
                    ? theme.palette.info.main
                    : theme.palette.neutral.dark,
                color: "white",
                marginLeft: 20,
                width: 60,
                cursor: savingBlocked ? "not-allowed" : "pointer",
            }}
            onClick={() => {
                if (savingBlocked) {
                    onBlockedSave();
                } else if (!savingInProgress) {
                    onSave();
                }
            }}
            data-cy="save-button"
        >
            {savingInProgress ? (
                <CircularProgress
                    style={{
                        height: 20,
                        width: 20,
                        color: "white",
                        textTransform: "uppercase",
                    }}
                />
            ) : (
                <span>{label}</span>
            )}
        </Button>
    );
}

export default SaveButton;
