import React from "react";
import { FilterListItem } from "../filter-dropdown/filter-dropdown";
import AutocompleteSelect from "../../components/detail-page-autocomplete/autocomplete-select";
import {
    sampleTypes,
    provisionalStatusTypes,
    taxonTypes,
    tissueSampleTypes,
    trueOrFalseTypes,
} from "../../modules/listItemsFromEnums";

type SamplesMenuProps = {
    activeGenus?: string;
    activeSpecies?: string;
    activeProject?: string;
    activeType?: string;
    activeTaxonType?: string;
    activeProvisionalStatus?: string;
    activeTissueType?: string;
    activeOrphanedStatus?: string;
    handleGenusChange: (event: any) => void;
    handleSpeciesChange: (event: any) => void;
    handleProjectChange: (event: any) => void;
    handleTypeChange: (event: any) => void;
    handleTaxonTypeChange: (event: any) => void;
    handleProvisionalStatusChange: (event: any) => void;
    handleTissueTypeChange: (event: any) => void;
    handleOrphanedStatusChange: (event: any) => void;
    genera?: FilterListItem[];
    species?: FilterListItem[];
    projects?: FilterListItem[];
};

function SamplesMenu({
    activeGenus,
    activeSpecies,
    activeProject,
    activeType,
    activeTaxonType,
    activeProvisionalStatus,
    activeTissueType,
    activeOrphanedStatus,
    handleGenusChange,
    handleProjectChange,
    handleSpeciesChange,
    handleTypeChange,
    handleTaxonTypeChange,
    handleProvisionalStatusChange,
    handleTissueTypeChange,
    handleOrphanedStatusChange,
    genera,
    species,
    projects,
}: SamplesMenuProps) {
    return (
        <>
            <AutocompleteSelect
                handleChange={handleProjectChange}
                label="Project"
                value={activeProject ? activeProject : ""}
                menuItems={projects}
            />
            <AutocompleteSelect
                label="Type of sample"
                value={activeType ? activeType : ""}
                handleChange={handleTypeChange}
                menuItems={sampleTypes}
            />
            {activeType === "Tissue" && (
                <AutocompleteSelect
                    label="Tissue sample type"
                    value={activeTissueType ? activeTissueType : ""}
                    handleChange={handleTissueTypeChange}
                    menuItems={tissueSampleTypes}
                />
            )}
            <AutocompleteSelect
                label="Genus"
                value={activeGenus ? activeGenus : ""}
                handleChange={handleGenusChange}
                menuItems={genera}
            />
            {activeGenus && (
                <AutocompleteSelect
                    label="Species"
                    value={activeSpecies ? activeSpecies : ""}
                    handleChange={handleSpeciesChange}
                    menuItems={species}
                />
            )}
            <AutocompleteSelect
                label="Taxon Type"
                value={activeTaxonType || ""}
                handleChange={handleTaxonTypeChange}
                menuItems={taxonTypes}
            />
            {activeTaxonType === "Provisional" && (
                <AutocompleteSelect
                    label="Provisional Status"
                    value={activeProvisionalStatus || ""}
                    handleChange={handleProvisionalStatusChange}
                    menuItems={provisionalStatusTypes}
                />
            )}
            <AutocompleteSelect
                label="Show only samples without a taxon?"
                value={activeOrphanedStatus || "False"}
                handleChange={handleOrphanedStatusChange}
                menuItems={trueOrFalseTypes}
                disabled={true}
            />
        </>
    );
}

export default SamplesMenu;
