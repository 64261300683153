import qs from "qs";

export const parseUrlParams = (urlParams: string) => {
    const obj = qs.parse(urlParams);
    const { page, ...rest } = obj;
    const pageNumber = obj.page;

    let newPageNumber: string | undefined = undefined;
    if (pageNumber) {
        const numPage: number = parseInt(pageNumber as string);
        newPageNumber = numPage.toString();
    }

    if (!newPageNumber) {
        return obj;
    } else {
        return { ...rest, page: newPageNumber };
    }
};

export const stringifyUrlParams = (urlParams: object): string => {
    return qs.stringify(urlParams);
};
