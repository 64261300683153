import React from "react";
import ReactDOM from "react-dom";
import store from "./ducks/store";
import { Provider } from "react-redux";
import "./style/index.css";
import * as serviceWorker from "./serviceWorker";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "./style/theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const render = () => {
    const Root = require("./pages/root").default;
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <MuiThemeProvider theme={theme}>
                        <Root />
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>
            </Provider>
        </React.StrictMode>,
        document.getElementById("root")
    );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./pages/root", render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
