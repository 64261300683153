import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { TableColumn } from "./info-table";
import { formatKeyForUrl } from "../../modules/formatters";
import { AppPage } from "../../ducks/ui";
import AppLink from "../app-link/app-link";
import { TableCellAlignment } from "./info-table";
import { cellValueToString } from "../../modules/cellValueToString";

type InfoTableCellProps = {
    row: any;
    align: TableCellAlignment;
    column: TableColumn;
    backToPage: AppPage;
};

const formatTableCell = (
    row: any,
    col: TableColumn,
    truncate?: number | undefined
): JSX.Element => {
    if (col.format) {
        return col.format(row, col.key);
    } else {
        return <span>{formatRowString(row, col, truncate)}</span>;
    }
};

const formatRowString = (row: any, col: TableColumn, truncate?: number | undefined): string => {
    const rawValue: any = row[col.key];
    const cellValue: string = cellValueToString(rawValue, truncate);
    return cellValue;
};

const constructRowUrl = (path: string, row: any, linkKey: string, subPathKey?: string): string => {
    const subPath = subPathKey ? formatKeyForUrl(row[subPathKey] as string) : "";
    return `${path}${subPath}${row[linkKey]}`;
};

function InfoTableCell({ row, column, backToPage, align }: InfoTableCellProps) {
    const { key, path, linkKey, subPathKey, truncate } = column;

    return (
        <TableCell key={`${column.key}-${key}`} align={align} style={{ padding: 12 }}>
            {linkKey && path ? (
                <AppLink
                    to={constructRowUrl(path, row, linkKey, subPathKey)}
                    backToPage={backToPage}
                    innerText={formatTableCell(row, column, truncate)}
                />
            ) : (
                <span title={truncate ? formatRowString(row, column) : ""}>
                    {formatTableCell(row, column, truncate)}
                </span>
            )}
        </TableCell>
    );
}

export default InfoTableCell;
