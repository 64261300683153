import React, { useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { useDispatch } from "react-redux";
import {
    loadSpeciesData,
    loadProjectsData,
    loadCollectorsData,
    selectProjectData,
    selectCollectorsData,
} from "../ducks/constant-data";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "../ducks/root-reducer";
import { isEmpty } from "lodash/fp";
import { selectAuthenticationState, AuthenticationState } from "../ducks/user";

// A wrapper for <Route> that redirects to login if not authenticated.
// Also fetches global stuff that is required for all logged in routes
const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
    const speciesData = useSelector(s => s.constantData.speciesByGenera);
    const projectsData = useSelector(selectProjectData);
    const collectorsData = useSelector(selectCollectorsData);
    const authState = useSelector(selectAuthenticationState);

    const dispatch = useDispatch();

    // Load data used for all logged in routes
    useEffect(() => {
        dispatch(loadSpeciesData());
        dispatch(loadProjectsData());
        dispatch(loadCollectorsData());
    }, [dispatch]);

    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (authState === AuthenticationState.Unauthenticated) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location },
                            }}
                        />
                    );
                }

                const loading =
                    isEmpty(speciesData) || isEmpty(projectsData) || isEmpty(collectorsData);

                return loading ? (
                    <div
                        style={{
                            width: "100vw",
                            height: "100vh",
                            display: "grid",
                            alignItems: "center",
                            justifyItems: "center",
                        }}
                    >
                        <CircularProgress color={"secondary"} style={{ height: 100, width: 100 }} />
                    </div>
                ) : (
                    children
                );
            }}
        />
    );
};

export default PrivateRoute;
