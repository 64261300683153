import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { FilterListItem } from "../../components/filter-dropdown/filter-dropdown";
import { useTheme } from "@material-ui/core/styles";
import { toggleStringInSemicolonString, fuzzySemicolonStringIncludes } from "./checkbox-helpers";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

type DetailFormCheckboxProps = {
    handleChange: (e: any) => void;
    label: string;
    isReadOnly: boolean;
    options: FilterListItem[];
    checkedValues: string; // Semicolon separated string that represents the checked values
    error: boolean;
    errorText: string;
};

function DetailFormCheckboxes({
    handleChange,
    label,
    isReadOnly,
    options,
    checkedValues,
    error,
    errorText,
}: DetailFormCheckboxProps) {
    const classes = useStyles();
    const theme = useTheme();

    const onCheckboxChange = (checkboxValue: string, checkedVals: string) => {
        const newCheckboxString = toggleStringInSemicolonString(checkedVals, checkboxValue);
        handleChange({ target: { value: newCheckboxString } });
    };

    const getOptionLabel = (option: FilterListItem) => {
        // Mark legacy values from the old app as such
        if (option.isLegacyValue) {
            return `${option.label} [legacy value]`;
        }

        return option.label;
    };

    return (
        <div>
            <FormControl error={error} component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">
                    <Typography
                        variant="caption"
                        style={{ display: "block", color: theme.palette.text.secondary }}
                    >
                        {label}
                    </Typography>
                </FormLabel>
                <FormGroup>
                    {options.map((option, i) => {
                        return (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={fuzzySemicolonStringIncludes(
                                            option.label,
                                            checkedValues
                                        )}
                                        style={{ color: theme.palette.info.main }}
                                        onChange={e => {
                                            onCheckboxChange(e.target.value, checkedValues);
                                        }}
                                        name={option.label}
                                        disabled={isReadOnly}
                                        value={option.label}
                                    />
                                }
                                label={getOptionLabel(option)}
                                key={i}
                            />
                        );
                    })}
                </FormGroup>
                {error && <FormHelperText>{errorText}</FormHelperText>}
            </FormControl>
        </div>
    );
}

export default DetailFormCheckboxes;
