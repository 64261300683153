import { DartOrderDetailSpecies } from "rbgds-shared";
import { PlateItemKey } from "../../hooks/useWellPlate";

// TYPES //

export type PlateOptions = {
    numRows: number;
    numCols: number;
    unusedIndexes: number[];
};
export type SpeciesColors = {
    speciesByColor: any;
    colors: string[];
    currentColorIndex: number;
};
export type DartOrderDetailSpeciesKey = keyof DartOrderDetailSpecies | "numIndividuals";
type OrderSummaryTableColumn = {
    key: DartOrderDetailSpeciesKey;
    label: string;
};
type WellPlateTableColumn = {
    key: PlateItemKey;
    label: string;
};

// CONSTANTS //

const NUM_COLS = 12;
const NUM_ROWS = 8;
export const WELL_PLATE_COLORS = [
    "#9F6355",
    "#718F60",
    "#D1B96F",
    "#A8A66A",
    "#EFD822",
    "#926C3F",
    "#4F5243",
    "#328F7F",
    "#958858",
    "#AA4741",
];
export const PLATE_OPTIONS: PlateOptions = {
    numCols: NUM_COLS,
    numRows: NUM_ROWS,
    unusedIndexes: [95, 94],
};

// TABLE COLUMNS //

export const orderSummaryTableCols: OrderSummaryTableColumn[] = [
    {
        key: "acceptedName",
        label: "Accepted name",
    },
    {
        key: "numIndividuals",
        label: "# individuals",
    },
    {
        key: "isFinalOrder",
        label: "Final order",
    },
    {
        key: "analysisStatus",
        label: "Analysis status",
    },
];
export const wellPlateTableCols: WellPlateTableColumn[] = [
    {
        key: "plateNumber",
        label: "Plate",
    },
    {
        key: "row",
        label: "Row",
    },
    {
        key: "column",
        label: "Col",
    },
    {
        key: "organism",
        label: "Organism",
    },
    {
        key: "species",
        label: "Species",
    },
    {
        key: "nswNumber",
        label: "Genotype",
    },
    {
        key: "inPlate",
        label: "In plate",
    },
];
