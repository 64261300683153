import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { TaxonListResponse, TaxonListItem, TaxonDetail, PaginationInfo } from "rbgds-shared";
import { QueryBase } from "../types/queries";
import { debounce } from "lodash/fp";
import { AppThunk } from "./store";
import { api } from "../modules/api";
import qs from "qs";
import { RootState } from "./root-reducer";
import { selectCurrentSearchQuery, setCurrentSearchQuery } from "./ui";

export type TaxonListQuery = {
    genus?: string;
    species?: string;
} & QueryBase;

export type TaxonState = {
    searchResult?: TaxonListResponse;
    loading: boolean;
    activeTaxon?: TaxonDetail;
};

export type TaxonListItemKey = keyof TaxonListItem;

const initialState: TaxonState = {
    loading: false,
};

const taxonSlice = createSlice({
    initialState,
    name: "species",
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setSpecies(state, action: PayloadAction<TaxonListResponse>) {
            state.searchResult = action.payload;
        },
        setActiveTaxon(state, action: PayloadAction<TaxonDetail>) {
            state.activeTaxon = action.payload;
        },
    },
});
export default taxonSlice;

// ----------------------------------------------------------------------------
// Selectors

export const selectSpeciesResults = (state: RootState): TaxonListItem[] | undefined => {
    return state?.taxon.searchResult?.results;
};

export const selectSpeciesPagination = (state: RootState): PaginationInfo | undefined => {
    return state?.taxon.searchResult?.pagination;
};

export const selectActiveTaxon = (state: RootState): TaxonDetail | undefined => {
    return state?.taxon.activeTaxon;
};

export const selectActiveTaxonSynonyms = (state: RootState): string | undefined => {
    return state?.taxon.activeTaxon?.synonymy;
};

// ----------------------------------------------------------------------------
// Thunks

// needs to be debounced since we will fire this off based on the search input field.
// debounced fn needs to have the inner function defined outside
const searchInner = debounce(
    50,
    async (dispatch: Dispatch, taxonListQuery: TaxonListQuery, getState) => {
        dispatch(taxonSlice.actions.setLoading(true));

        const query = qs.stringify(taxonListQuery);
        // set the current search query in the ui state
        await dispatch(setCurrentSearchQuery(query));

        const res = await api(`taxon?${query}`);
        const data: TaxonListResponse = await res.json();

        // the response can sometimes come back later than the next search request
        // so we need to check that the current search query is the same as the one
        // we just got a response for [else return].
        const currentSearchQuery = selectCurrentSearchQuery(getState());
        if (currentSearchQuery && currentSearchQuery !== query) return null;

        dispatch(taxonSlice.actions.setSpecies(data));
        dispatch(taxonSlice.actions.setLoading(false));
        dispatch(setCurrentSearchQuery("")); //reset the current search query
    }
);

export const searchSpecies = (taxonListQuery: TaxonListQuery): AppThunk => {
    return (dispatch, getState) => {
        searchInner(dispatch, taxonListQuery, getState);
    };
};
