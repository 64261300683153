import React from "react";
import { Popup } from "react-map-gl";
import { Position } from "geojson";
import { MapItem, MapItemType } from "rbgds-shared";
import {
    formatTzTimestamp,
    semicolonStringToCommaString,
    formatKeyForUrl,
} from "../../modules/formatters";
import AppLink from "../app-link/app-link";

type InfoPopupProps = {
    sample: MapItem;
    coordinates: Position;
    onClose: () => any;
};

export const InfoPopup = ({ coordinates, sample, onClose }: InfoPopupProps) => {
    const [long, lat] = coordinates;

    const { type, acceptedName, locality } = sample;

    return (
        <Popup
            tipSize={20}
            anchor="top"
            latitude={lat}
            longitude={long}
            closeOnClick={false}
            onClose={() => onClose()}
        >
            <div>
                <div>
                    <h4>{acceptedName}</h4>
                </div>
                {"id" in sample && (
                    <p>
                        <AppLink
                            to={`samples/${formatKeyForUrl(type)}${sample.id}`}
                            backToPage={"map"}
                            innerText={sample.id}
                        />
                    </p>
                )}
                {"siteKey" in sample && (
                    <p>
                        <div>
                            <span style={{ fontWeight: "bold" }}>Site Key: </span>
                            <span>{sample.siteKey}</span>
                        </div>
                    </p>
                )}
                <div>
                    <span style={{ fontWeight: "bold" }}>Type: </span>
                    <span>{type}</span>
                </div>
                {"associatedProjects" in sample && (
                    <div>
                        <span style={{ fontWeight: "bold" }}>Project(s): </span>
                        <span>{semicolonStringToCommaString(sample.associatedProjects)}</span>
                    </div>
                )}
                {sample.type !== MapItemType.ALAHerbarium &&
                    sample.type !== MapItemType.NSWVisSurvey &&
                    "peoplePresent" in sample && (
                        <div>
                            <span style={{ fontWeight: "bold" }}>Collector: </span>
                            <span>{semicolonStringToCommaString(sample.peoplePresent)}</span>
                        </div>
                    )}
                {"sampleDate" in sample && sample.sampleDate && (
                    <div>
                        <span style={{ fontWeight: "bold" }}>Collecting date: </span>
                        <span>{formatTzTimestamp(sample.sampleDate || "")}</span>
                    </div>
                )}
                {"year" in sample && sample.year && (
                    <div>
                        <span style={{ fontWeight: "bold" }}>Year: </span>
                        <span>{formatTzTimestamp(String(sample.year) || "")}</span>
                    </div>
                )}
                {sample.locality && (
                    <div>
                        <span style={{ fontWeight: "bold" }}>Locality: </span>
                        <span>{locality}</span>
                    </div>
                )}
            </div>
        </Popup>
    );
};

export default InfoPopup;
