import React from "react";
import NewItemForm from "./new-item-form";
import { useNewItemForm } from "../../hooks/useNewItemForm";
import { newImportFields } from "./new-item-field-config";
import { strings } from "../../modules/strings/strings";
const { addItemModalStrings } = strings;

type NewImportFormProps = {
    handleClose: () => void;
    isRepairImport: boolean;
};

function NewImportForm({ handleClose, isRepairImport }: NewImportFormProps) {
    const { submit, setPostValue, displayData, errors, savingStatus } = useNewItemForm<{
        importFiles: FormData;
    }>(
        isRepairImport ? "import/repair" : "import/manual",
        newImportFields,
        [{ key: "importFiles", value: [] }],
        true
    );

    return (
        <NewItemForm
            onSubmit={submit}
            displayData={displayData}
            handleClose={handleClose}
            heading={
                isRepairImport ? addItemModalStrings.repairImport : addItemModalStrings.addImport
            }
            subheading={
                isRepairImport
                    ? addItemModalStrings.repairImportSubheading
                    : addItemModalStrings.addImportSubheading
            }
            formFields={newImportFields}
            setPostValue={setPostValue}
            errors={errors}
            savingStatus={savingStatus}
            newItemPath={"import"}
            backToPage="import"
            // Temporarily disabling repair import
            disabled={isRepairImport ? true : false}
        />
    );
}

export default NewImportForm;
