import React from "react";
import FilterDropdown, { FilterListItem } from "../../components/filter-dropdown/filter-dropdown";
import DetailFormFieldWrapper from "../detail-form/detail-form-field-wrapper";

type DetailFormFilterProps = {
    handleChange: (e: any) => void;
    label: string;
    isReadOnly: boolean;
    value: string;
    options: FilterListItem[];
};

function DetailFormFilter({
    handleChange,
    label,
    isReadOnly,
    value,
    options,
}: DetailFormFilterProps) {
    const filter = (
        <FilterDropdown
            label={label}
            value={value}
            menuItems={options}
            handleChange={handleChange}
            options={{ removeEmptyOption: true }}
        />
    );

    return (
        <DetailFormFieldWrapper body={filter} isReadOnly={isReadOnly} error={{ error: false }} />
    );
}

export default DetailFormFilter;
