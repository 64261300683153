import React from "react";
import { MapItemType } from "rbgds-shared";
import { FilterListItem } from "../filter-dropdown/filter-dropdown";
import MapCheckboxes from "./map-checkboxes";
import AutocompleteSelect from "../../components/detail-page-autocomplete/autocomplete-select";

type MapMenuProps = {
    activeGenus?: string;
    activeSpecies?: string;
    activeProject?: string;
    activeType?: string;
    activeCollector?: string;
    handleTaxonChange: (event: any) => void;
    handleProjectChange: (event: any) => void;
    handleCollectorChange: (event: any) => void;
    handleMapTypeChange: (types: MapItemType[]) => void;
    speciesWithGenera?: FilterListItem[];
    collectors?: FilterListItem[];
    projects?: FilterListItem[];
};

function MapMenu({
    activeGenus,
    activeSpecies,
    activeProject,
    activeType,
    activeCollector,
    handleTaxonChange,
    handleProjectChange,
    handleMapTypeChange,
    handleCollectorChange,
    speciesWithGenera,
    projects,
    collectors,
}: MapMenuProps) {
    return (
        <>
            <AutocompleteSelect
                label="Project"
                value={activeProject || ""}
                handleChange={handleProjectChange}
                menuItems={projects}
            />
            <AutocompleteSelect
                label="Taxon"
                // a little hacky, but for some reason it (mostly) fixes a bug where the
                // value wouldn't show up on initial page load [but would show up
                // after a selection was made] - useMemo related? :shrug:
                value={`${activeGenus || ""} ${activeSpecies || ""}`}
                handleChange={handleTaxonChange}
                menuItems={speciesWithGenera}
            />
            <MapCheckboxes handleMapTypeChange={handleMapTypeChange} activeType={activeType} />
            <AutocompleteSelect
                label="Collector"
                value={activeCollector || ""}
                handleChange={handleCollectorChange}
                menuItems={collectors}
            />
        </>
    );
}

export default MapMenu;
