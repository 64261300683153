import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    actionButton: {
        height: 40,
        backgroundColor: "white",
        border: `1px solid ${theme.palette.neutral.dark}`,
        boxShadow: "none",
        textTransform: "uppercase",
    },
}));

type Severity = "info" | "danger";

type ActionButtonProps = {
    label: string;
    onClick: () => void;
    severity?: Severity;
    startIcon?: JSX.Element;
    endIcon?: JSX.Element;
    disabled?: boolean;
    dataCy?: string;
};

function ActionButton({
    label,
    startIcon,
    endIcon,
    severity,
    onClick,
    dataCy,
    disabled = false,
}: ActionButtonProps) {
    const classes = useStyles();
    const theme = useTheme();

    const infoColor = theme.palette.info.main;
    const dangerColor = theme.palette.warning.main;
    return (
        <Button
            className={classes.actionButton}
            style={{ color: severity && severity === "danger" ? dangerColor : infoColor }}
            variant="contained"
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={onClick}
            disabled={disabled}
            data-cy={dataCy}
        >
            {label}
        </Button>
    );
}

export default ActionButton;
