import * as React from "react";

export function EditIcon(props: any) {
    return (
        <svg height={24} viewBox="0 0 24 24" width={24} {...props}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                fill="currentColor"
                d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            />
        </svg>
    );
}

export function MapMarker(props: any) {
    return (
        <svg height={24} viewBox="0 0 24 24" width={24} {...props}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                fill="currentColor"
                d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"
            />
        </svg>
    );
}

export function LeafIcon(props: any) {
    return (
        <svg height={100} width={100} viewBox="0 0 24 24" {...props}>
            <title>{"nature-leaf, tree, branch, forrest"}</title>
            <path
                fill="currentColor"
                d="M3 22a1 1 0 00.707-.293l2.967-2.967a7.509 7.509 0 004.2 1.235 8.817 8.817 0 006.332-2.768C21.02 13.4 21.958 3.509 22 3.09a1 1 0 00-.289-.8A1.012 1.012 0 0020.91 2c-.419.038-10.3.976-14.117 4.789a8.817 8.817 0 00-2.768 6.332 7.509 7.509 0 001.235 4.2l-2.967 2.972A1 1 0 003 22zM8.207 8.207c2.584-2.583 8.956-3.7 11.65-4.063-.365 2.693-1.477 9.062-4.064 11.649a6.859 6.859 0 01-4.918 2.182 5.617 5.617 0 01-2.751-.685l4.583-4.583a1 1 0 00-1.414-1.414L6.71 15.876a5.617 5.617 0 01-.685-2.751 6.859 6.859 0 012.182-4.918z"
            />
        </svg>
    );
}

export function FlowerIcon(props: any) {
    return (
        <svg height={100} width={100} viewBox="0 0 24 24" {...props}>
            <title>{"nature-rose, plant, leaf"}</title>
            <path
                fill="currentColor"
                d="M4.351 14.063A1 1 0 003 15v3a5.006 5.006 0 005 5h8a5.006 5.006 0 005-5v-3a1 1 0 00-1.352-.937L13 16.557V13.92A7.006 7.006 0 0019 7V2a1 1 0 00-1.707-.707l-3.019 3.019L12.9 1.553a1.042 1.042 0 00-1.79 0L9.726 4.312 6.707 1.293A1 1 0 005 2v5a7 7 0 006 6.92v2.637zM7 7V4.414l2.293 2.293a1.013 1.013 0 00.867.28 1 1 0 00.735-.54L12 4.236l1.105 2.211a1 1 0 001.6.26L17 4.414V7A5 5 0 017 7zm4.649 11.937a1.009 1.009 0 00.7 0L19 16.443V18a3 3 0 01-3 3H8a3 3 0 01-3-3v-1.557z"
            />
        </svg>
    );
}

export function DnaIcon(props: any) {
    return (
        <svg height={100} width={100} viewBox="0 0 24 24" {...props}>
            <title>{"medical, dna, blood, structure"}</title>
            <path
                fill="currentColor"
                d="M6 22a1 1 0 002 0c0-.023.124-2.226 4-3.91 3.812 1.655 3.993 3.8 4 3.928A1 1 0 0018 22c0-.13-.056-2.8-3.657-4.957C17.694 15.34 18 13.841 18 12s-.306-3.34-3.657-5.043C17.944 4.8 18 2.13 18 2a1 1 0 00-2 0c0 .023-.124 2.226-4 3.91C8.156 4.241 8 2.074 8 2a1 1 0 00-2 0c0 .13.056 2.8 3.657 4.957C6.306 8.66 6 10.159 6 12s.306 3.34 3.657 5.043C6.056 19.2 6 21.87 6 22zm2-10c0-1.308.012-2.266 4-3.917 3.988 1.651 4 2.609 4 3.917s-.012 2.266-4 3.917C8.012 14.266 8 13.308 8 12z"
            />
        </svg>
    );
}

export function ImportIcon(props: any) {
    return (
        <svg height={100} width={100} viewBox="0 0 35 35" {...props}>
            <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z" />
            <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z" />
            <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z" />
        </svg>
    );
}

export function MapIcon(props: any) {
    return (
        <svg height={100} width={100} viewBox="0 0 24 24" {...props}>
            <title>{"location, place, map, gps, pin"}</title>
            <path
                fill="currentColor"
                d="M12 6a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"
            />
            <path
                fill="currentColor"
                d="M20.992 9.98A8.991 8.991 0 003.01 9.932a13.95 13.95 0 008.574 12.979A1 1 0 0012 23a1.012 1.012 0 00.419-.09 13.948 13.948 0 008.573-12.93zM12 20.9A11.713 11.713 0 015.008 10a6.992 6.992 0 1113.984 0v.065A11.7 11.7 0 0112 20.9z"
            />
        </svg>
    );
}

export function StackIcon(props: any) {
    return (
        <svg height={100} width={100} viewBox="0 0 24 24" {...props}>
            <title>{"layer, stack, bundle"}</title>
            <path
                fill="currentColor"
                d="M3.615 6.256l8 3.333a.989.989 0 00.77 0l8-3.333a1 1 0 000-1.845l-8-3.334a1.007 1.007 0 00-.77 0l-8 3.334a1 1 0 000 1.845zM12 3.083l5.4 2.25-5.4 2.25-5.4-2.25z"
            />
            <path
                fill="currentColor"
                d="M20.923 9.393a1 1 0 00-1.308-.538L12 12.028 4.385 8.855a1 1 0 10-.77 1.846l8 3.333a1 1 0 00.77 0l8-3.333a1 1 0 00.538-1.308z"
            />
            <path
                fill="currentColor"
                d="M19.615 13.3L12 16.472 4.385 13.3a1 1 0 10-.77 1.846l8 3.334a1 1 0 00.77 0l8-3.334a1 1 0 10-.77-1.846z"
            />
            <path
                fill="currentColor"
                d="M3.615 19.589l8 3.334a1 1 0 00.77 0l8-3.334a1 1 0 10-.77-1.845L12 20.917l-7.615-3.173a1 1 0 10-.77 1.845z"
            />
        </svg>
    );
}
