import React from "react";
import { useWellPlates, WellPlates } from "../../hooks/useWellPlate";
import { SetPatchFunction } from "../../hooks/useDetailForm";
import WellPlateGrid from "./well-plate-grid";
import WellPlateTable from "./well-plate-table";
import SpeciesTable from "./species-table";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { DartOrderDetailSpecies, DartOrderDetailSample } from "rbgds-shared";
import { removePlate } from "./well-plate-helpers";
import { PLATE_OPTIONS } from "./well-plate-config";
import ActionButton from "../button/action-button";

type WellPlateFormProps = {
    id: string; // Service number of order
    plateHeight?: string;
    plateWidth?: string;
    displayData: any;
    setPatchValue: SetPatchFunction;
};

function WellPlateForm({ displayData, setPatchValue, id }: WellPlateFormProps) {
    // Frontend state for well plates
    const wellPlates: WellPlates = useWellPlates(displayData.samples, displayData.species);

    // Remove plate
    const onClickToRemovePlate = (
        plateIndexToRemove: number,
        allSamples: DartOrderDetailSample[],
        allSpecies: DartOrderDetailSpecies[]
    ): void => {
        const plateNumToRemove = plateIndexToRemove + 1;
        const samplesToRemove = allSamples.filter(s => s.plateNumber === plateNumToRemove);

        const warning = `Are you sure you want to remove plate ${plateNumToRemove}? This plate contains ${
            samplesToRemove.length
        } ${samplesToRemove.length === 1 ? "sample" : "samples"}.`;

        if (window.confirm(warning)) {
            const newSamplesAndSpecies = removePlate(allSamples, allSpecies, plateNumToRemove);
            setPatchValue("sampleAndSpecies", newSamplesAndSpecies, id);
            wellPlates.removePlate(plateIndexToRemove); // Decrement # plates FE
        }
    };

    return (
        <div>
            <div style={{ margin: "80px 0px 40px 0px" }}>
                <h3>Well plate design</h3>
                <p>
                    Scan NSW numbers in the desired order. Fields will be automatically populated.
                    The export file will contain columns for type and comments.
                </p>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <div style={{ flexGrow: 1, marginRight: 80 }}>
                    <WellPlateTable
                        id={id}
                        wellPlates={wellPlates}
                        options={PLATE_OPTIONS}
                        setPatchValue={setPatchValue}
                        species={displayData.species}
                        samples={displayData.samples}
                    />
                    <h3 style={{ marginTop: 80 }}>Order summary</h3>
                    <SpeciesTable
                        id={id}
                        setPatchValue={setPatchValue}
                        species={displayData.species}
                        samples={displayData.samples}
                    />
                </div>
                <div style={{ marginRight: 40 }}>
                    <WellPlateGrid
                        wellPlates={wellPlates}
                        options={PLATE_OPTIONS}
                        samples={displayData.samples}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "flex-end",
                        }}
                    >
                        {wellPlates.getNumPlates() > 1 && (
                            <ActionButton
                                label="Remove plate"
                                endIcon={<DeleteIcon />}
                                severity="danger"
                                onClick={() => {
                                    onClickToRemovePlate(
                                        wellPlates.getCurrentPlateIndex(),
                                        displayData.samples,
                                        displayData.species
                                    );
                                }}
                            />
                        )}
                        <div style={{ marginLeft: 20 }}>
                            <ActionButton
                                label="Add plate"
                                endIcon={<AddIcon />}
                                onClick={wellPlates.addPlate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WellPlateForm;
