import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";

type ToastProps = {
    message: string;
    error: boolean;
};

export default function Toast({ message, error }: ToastProps) {
    const [open, setOpen] = React.useState(true);

    const handleClose = (event: any) => {
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            open={open}
            autoHideDuration={2500}
            onClose={handleClose}
            // message={message}
            action={
                <React.Fragment>
                    <IconButton onClick={handleClose}>
                        <CloseIcon fontSize="small" style={{ color: "white" }} />
                    </IconButton>
                </React.Fragment>
            }
        >
            <MuiAlert elevation={6} variant="filled" severity={error ? "error" : "success"}>
                {message}
            </MuiAlert>
        </Snackbar>
    );
}
