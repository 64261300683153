import { useState } from "react";
import { useDispatch } from "react-redux";
import { loadSpeciesData } from "../ducks/constant-data";
import { api, DetailPath } from "../modules/api";
import { NewItemModalField, NewItemKey } from "../types/post-new";
import { DataError, SavingStatus } from "../types/form-types";
import { getFieldByKey } from "../modules/new-item-helpers/new-item-helpers";
import { strings } from "../modules/strings/strings";
const { notifications } = strings;

export function useNewItemForm<DisplayType>(
    postPath: DetailPath,
    fields: NewItemModalField[],
    defaultValues: { key: keyof DisplayType; value: any }[],
    isFileUpload?: boolean
) {
    const dispatch = useDispatch();
    const defaultData = defaultValues.reduce((acc, curr) => {
        return { ...acc, [curr.key]: curr.value };
    }, {});

    const [displayData, setDisplayData] = useState<any>(defaultData);
    const [errors, setErrors] = useState<Map<NewItemKey, DataError>>(
        new Map(fields.map(f => [f.key, { error: false }]))
    );
    const [savingStatus, setSavingStatus] = useState<SavingStatus>({
        savingInProgress: false,
        savingError: { error: false },
    });

    // Return type
    type NewItemForm = {
        displayData: DisplayType;
        setPostValue: (key: string, value: any) => void; // (function to setState for post data)
        submit: (postData: DisplayType) => Promise<SavingStatus>; // Function to send post request that doesn't resolve till submitted
        errors: Map<NewItemKey, DataError>;
        savingStatus: SavingStatus;
    };

    // PATCH DATA
    const sendPost = async (postData: DisplayType): Promise<SavingStatus> => {
        // Send patch
        setSavingStatus({ savingInProgress: true, savingError: { error: false } });

        const formData = isFileUpload ? (postData as any).importFiles : JSON.stringify(postData);

        // Temporary hacks to get express & django to play nice
        const additionalHeaders: any = isFileUpload ? {} : { "Content-Type": "application/json" };

        const res = await api(
            `${postPath}`,
            {
                method: "POST",
                body: formData,
                headers: additionalHeaders,
            },
            true
        );

        if (res.ok) {
            let status: SavingStatus = {
                savingInProgress: false,
                savingError: { error: false, message: notifications.detailFormSaved },
            };

            // The taxonKey is autogenerated, so in order to navigate to the detail page after taxon
            // creation we add the key to the status once it has been returned.
            if (postPath === "taxon") {
                const parsedResponse = await res.clone().json();
                let taxonKey = parsedResponse.taxonKey ? parsedResponse.taxonKey : "";
                status.idStatus = { changed: true, newId: taxonKey };
                dispatch(loadSpeciesData());
            }

            setSavingStatus(status);
            return status;
        } else {
            const errorMessage = await res.json();
            const msg = `${notifications.detailFormSavingFailed}${
                res.status ? `: [${res.status}] ${errorMessage?.message || res.statusText}` : ""
            }`;
            const status = {
                savingInProgress: false,
                savingError: { error: true, message: msg },
            };
            setSavingStatus(status);
            return status;
        }
    };

    const setPostValue = (key: NewItemKey, value: any): void => {
        const validate = getFieldByKey(key, fields)?.validate;
        if (validate) {
            validate(value).then(resp => {
                setErrors(e => new Map(e.set(key, resp)));
            });
        }
        setDisplayData({ ...displayData, [key]: value });
    };

    return {
        displayData: displayData as DisplayType,
        setPostValue: setPostValue,
        submit: sendPost,
        errors: errors,
        savingStatus: savingStatus,
    } as NewItemForm;
}
