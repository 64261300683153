import React, { useState } from "react";
import { isEmpty } from "lodash";
import DetailForm from "../../components/detail-form/detail-form";
import DetailPageHeader from "../../components/detail-page-header/detail-page-header";
import { DetailPageSection, DetailKey } from "../../types/details";
import { DataError, SavingStatus } from "../../types/form-types";
import Toast from "../../components/toast/toast";
import { AppPage } from "../../ducks/ui";
import { SetPatchFunction } from "../../hooks/useDetailForm";
import { DetailPagePath } from "../../modules/api";
import { useHistory } from "react-router-dom";

type DetailPageProps = {
    id: string; // id of entity, e.g. NSWnumber
    header: string;
    formSections: DetailPageSection[];
    displayData: any;
    patchData: any;
    submit: (id: string, patch: any) => Promise<SavingStatus>; // Function to send patch request that doesn't resolve till submitted.
    undoUnsavedChanges: () => void;
    setPatchValue: SetPatchFunction;
    errors: Map<DetailKey, DataError>;
    savingStatus: SavingStatus;
    backToPage: AppPage;
    detailPagePath: DetailPagePath;
    isReadOnly?: boolean;
};

type ToastStatus = { isOpen: boolean; hasError: boolean; message?: string };

function DetailPage({
    id,
    formSections,
    displayData,
    patchData,
    submit,
    setPatchValue,
    undoUnsavedChanges,
    errors,
    header,
    backToPage,
    detailPagePath,
    savingStatus,
    isReadOnly,
}: DetailPageProps) {
    const history = useHistory();
    const [toastStatus, setToastStatus] = useState<ToastStatus>({
        isOpen: false,
        message: "",
        hasError: false,
    });

    let savingBlocked: boolean = false;
    errors.forEach(e => {
        if (e.blocksSaving) savingBlocked = true;
    });

    return (
        <div>
            {displayData && (
                <>
                    <DetailPageHeader
                        headerTitle={header || ""}
                        isReadOnly={isReadOnly}
                        onSaveForm={() => {
                            submit(id, patchData)
                                .then(status => {
                                    const msg = status.savingError.message;
                                    if (status.savingError.error) {
                                        setToastStatus({
                                            isOpen: true,
                                            hasError: true,
                                            message: msg,
                                        });
                                    } else {
                                        setToastStatus({
                                            isOpen: true,
                                            hasError: false,
                                            message: msg,
                                        });
                                    }
                                    return status;
                                })
                                .then(status => {
                                    if (status.idStatus && status.idStatus.changed) {
                                        // "Orders" page is the only place that id can currently be changed
                                        // So just hardcoding for now.
                                        history.replace(
                                            `/dashboard/${detailPagePath}/${status.idStatus.newId}`,
                                            detailPagePath
                                        );
                                    }
                                });
                        }}
                        onCancel={undoUnsavedChanges}
                        savingInProgress={savingStatus.savingInProgress || false}
                        hasChanges={!isEmpty(patchData)}
                        savingBlocked={savingBlocked}
                    />
                    <DetailForm
                        id={id}
                        detailObject={displayData}
                        formFields={formSections}
                        onUpdateField={setPatchValue}
                        errors={errors}
                        backToPage={backToPage}
                    />
                </>
            )}
            {toastStatus.isOpen && (
                <Toast message={toastStatus.message || ""} error={toastStatus.hasError} />
            )}
        </div>
    );
}

export default DetailPage;
