import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useWellPlateStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        paper: {
            marginRight: theme.spacing(2),
        },
        tableHeaderButton: {
            height: 40,
            backgroundColor: "white",
            color: theme.palette.info.main,
            border: `1px solid ${theme.palette.neutral.dark}`,
            boxShadow: "none",
            textTransform: "uppercase",
        },
        menuItem: {
            width: 100,
            height: "auto",
        },
        dropdownWrapper: {
            zIndex: 100,
        },
        downloadLink: {
            textDecoration: "none",
            color: "inherit",
        },
        // Species table
        container: {
            maxHeight: 440,
        },
        headerCell: {
            fontWeight: "bold",
            padding: "6px 10px",
        },
        genotypeInput: {
            maxWidth: 100,
            border: `1px solid rgb(33, 150, 243)`,
            borderRadius: 3,
        },
        speciesDropdownFormControl: {
            width: "100%",
            minWidth: 120,
        },
        // Well plate grid
        paginationWrapper: {
            display: "grid",
            width: "100%",
            marginTop: 35,
            height: 30,
            alignItems: "center",
        },
    })
);
