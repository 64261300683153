import { TableColumn } from "../../components/info-table/info-table";
import {
    tableCellTimeStamp,
    commaString,
    addErrorIcon,
    minWidthNoWrap,
} from "../../modules/format-table-cells/format-table-cells";

export const sampleColumns: TableColumn[] = [
    {
        label: "NSW Number",
        key: "id",
        align: "left",
        linkKey: "id",
        subPathKey: "type",
        path: "samples/",
        truncate: 20,
    },
    { label: "Species Name", key: "acceptedName", align: "left", format: minWidthNoWrap },
    { label: "Field Species Name", key: "fieldSpeciesName", align: "left", format: minWidthNoWrap },
    { label: "Type", key: "type", align: "left" },
    { label: "Tissue Sample Type", key: "tissueSampleType", align: "left" },
    { label: "Collector", key: "peoplePresent", align: "left", truncate: 30, format: commaString },
    { label: "Collecting date", key: "sampleDate", align: "right", format: tableCellTimeStamp },
    { label: "Locality", key: "locality", align: "left", truncate: 30 },
    { label: "DArT orders", key: "dartOrderCount", align: "right" },
    { label: "Error", key: "error", align: "left", format: addErrorIcon },
];
