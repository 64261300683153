import React, { useMemo, useEffect, useState } from "react";
import { parseUrlParams, stringifyUrlParams } from "../../modules/query-helpers";
import { ColumnKey } from "../../components/info-table/info-table";
import InfoTable from "../../components/info-table/info-table";
import {
    searchSpecies,
    TaxonListQuery,
    selectSpeciesResults,
    selectSpeciesPagination,
} from "../../ducks/species";
import { setQuery, SortDirection } from "../../ducks/constant-data";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useSelector } from "../../ducks/root-reducer";
import LoadingOverlay from "../../components/loading-overlay/loading-overlay";
import TableHeader from "../../components/table-header/table-header";
import SearchBar from "../../components/search-bar/search-bar";
import { BASE_API_URL } from "../../modules/api";
import { sortableColumns } from "../../types/sortableColumns";
import { speciesColumns } from "./species-page-config";
import AddNewModal from "../../components/add-new-modal/add-new-modal";
import NewSpeciesForm from "../../components/add-new-modal/new-species-form";

function SpeciesPage() {
    const location = useLocation();
    const dispatch = useDispatch();
    const speciesResults = useSelector(selectSpeciesResults);
    const pagination = useSelector(selectSpeciesPagination);
    const isLoading = useSelector(s => s.taxon.loading);
    const [newModalOpen, setNewModalOpen] = useState<boolean>(false);

    const taxonListQuery: TaxonListQuery = useMemo(() => {
        return parseUrlParams(location.search.slice(1));
    }, [location.search]);

    const downloadLink: string = useMemo(() => {
        const { page, ...paramsWithoutPage } = taxonListQuery;
        const stringParams = paramsWithoutPage ? `${stringifyUrlParams(paramsWithoutPage)}` : "";

        return `${BASE_API_URL}/taxon.csv?${stringParams}`;
    }, [taxonListQuery]);

    useEffect(() => {
        dispatch(searchSpecies(taxonListQuery));
    }, [dispatch, taxonListQuery]);

    const { sortBy, sortDirection, query } = taxonListQuery;

    return (
        <div
            data-cy="add-species-table"
            style={{ minHeight: 500, position: "relative", height: "100%", width: "100%" }}
        >
            {isLoading && <LoadingOverlay />}
            <TableHeader
                title="Species"
                downloadOptions={[
                    {
                        label: "CSV",
                        downloadHref: downloadLink,
                    },
                ]}
                createNewOptions={[
                    {
                        label: "Add Species",
                        onClick: () => {
                            setNewModalOpen(true);
                        },
                        dataCy: "add-species-button",
                    },
                ]}
            />
            <SearchBar
                placeHolder={"Species name"}
                query={query}
                onKeyUp={newQuery => {
                    dispatch(setQuery(newQuery));
                }}
            />
            {speciesResults && (
                <InfoTable
                    rows={speciesResults}
                    columns={speciesColumns}
                    sortableColumns={sortableColumns.species}
                    pagination={pagination}
                    sortBy={sortBy as ColumnKey}
                    sortDirection={sortDirection as SortDirection}
                    backToPage={"species"}
                />
            )}
            <AddNewModal
                isOpen={newModalOpen}
                handleClose={() => {
                    setNewModalOpen(false);
                }}
                body={
                    <NewSpeciesForm
                        handleClose={() => {
                            setNewModalOpen(false);
                        }}
                    />
                }
            />
        </div>
    );
}

export default SpeciesPage;
