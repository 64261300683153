import React from "react";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DropdownButton from "../button/dropdown-button";
import ActionButton from "../button/action-button";
import { ButtonOption } from "../../types/ui";

export type CreateNewOptions = {
    label: string;
    onClick: () => void;
};

type TableHeaderProps = {
    title: string;
    downloadOptions?: ButtonOption[];
    createNewOptions?: ButtonOption[];
    extraOption?: ButtonOption;
};

function TableHeader({ title, downloadOptions, createNewOptions, extraOption }: TableHeaderProps) {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 16px 20px 16px",
            }}
        >
            <Typography component="h1" variant="h6" color="inherit" noWrap>
                {title}
            </Typography>
            <div style={{ display: "flex" }}>
                {createNewOptions?.length && (
                    <div>
                        {createNewOptions?.length === 1 ? (
                            // Single purpose button if one option, dropdown button otherwise
                            <ActionButton
                                label={createNewOptions[0].label}
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    createNewOptions[0].onClick && createNewOptions[0].onClick();
                                }}
                                dataCy={createNewOptions[0].dataCy}
                            />
                        ) : (
                            <DropdownButton
                                buttonLabel={"New sample"}
                                startIcon={<AddIcon />}
                                buttonOptions={createNewOptions}
                            ></DropdownButton>
                        )}
                    </div>
                )}

                {downloadOptions && downloadOptions.length && (
                    <div style={{ marginLeft: 10 }}>
                        <DropdownButton
                            buttonLabel={"Export"}
                            buttonOptions={downloadOptions}
                        ></DropdownButton>
                    </div>
                )}

                {extraOption && (
                    <div style={{ marginLeft: 10 }}>
                        <ActionButton
                            label={extraOption.label}
                            onClick={() => {
                                extraOption.onClick && extraOption.onClick();
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default TableHeader;
