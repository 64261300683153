import React from "react";
import { useParams } from "react-router-dom";
import { SamplingEventPatch, SamplingEventDetail } from "rbgds-shared";
import { useDetailForm } from "../../hooks/useDetailForm";
import DetailPage from "../../components/detail-page/detail-page";
import { eventsForm } from "./event-detail-config";
import EventDetailSamplesList from "./event-detail-samples";

function EventsDetailPage() {
    let { id } = useParams();

    const {
        displayData,
        patchData,
        setPatchValue,
        undoUnsavedChanges,
        submit,
        errors,
        savingStatus,
    } = useDetailForm<SamplingEventDetail, SamplingEventPatch>(
        id,
        "sampling-event",
        "sampling-event",
        eventsForm
    );

    return (
        <div>
            {displayData && (
                <>
                    <DetailPage
                        id={id}
                        header={"Sampling Event: " + displayData.eventID || ""}
                        formSections={eventsForm}
                        displayData={displayData}
                        patchData={patchData}
                        submit={submit}
                        setPatchValue={setPatchValue}
                        undoUnsavedChanges={undoUnsavedChanges}
                        errors={errors}
                        backToPage={"events"}
                        detailPagePath={"events"}
                        savingStatus={savingStatus}
                    />
                    <EventDetailSamplesList
                        species={displayData.species || []}
                        backToPage={"event details"}
                    />
                </>
            )}
        </div>
    );
}

export default EventsDetailPage;
