import React from "react";
import { Typography } from "@material-ui/core";
import AppLink from "../app-link/app-link";
import { AppPage } from "../../ducks/ui";
import DetailFormFieldWrapper from "../detail-form/detail-form-field-wrapper";
import { useTheme } from "@material-ui/core/styles";

type DetailPageLinkProps = {
    label: string;
    linkText: string;
    href: string;
    backToPage: AppPage;
    external: boolean | undefined;
};

function DetailPageLink({ label, linkText, href, backToPage, external }: DetailPageLinkProps) {
    const theme = useTheme();
    const link = (
        <div style={{ marginBottom: 10 }}>
            <Typography
                variant="caption"
                style={{ display: "block", color: theme.palette.text.secondary }}
            >
                {label}
            </Typography>
            {href && linkText ? (
                <AppLink
                    to={href}
                    variant="body1"
                    innerText={linkText.replace(/http:\/\/(www.)?/, "")}
                    backToPage={backToPage}
                    external={external}
                />
            ) : (
                <Typography variant={"inherit"}>
                    <span style={{ whiteSpace: "nowrap" }}>{"-"}</span>
                </Typography>
            )}
        </div>
    );

    return <DetailFormFieldWrapper body={link} isReadOnly={true} error={{ error: false }} />;
}

export default DetailPageLink;
