import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./root-reducer";
import { AppThunk } from "./store";
import { QueryBase } from "../types/queries";
import qs from "qs";
import { api } from "../modules/api";
import { MapResponse, MapItem } from "rbgds-shared";

type MapQuery = {
    genus?: string;
    species?: string;
    project?: string;
    type?: string;
} & QueryBase;

export type MapState = {
    loading: boolean;
    activeSample?: MapItem;
    searchResult?: MapResponse;
};

const initialState: MapState = {
    loading: false,
};

const mapSlice = createSlice({
    initialState,
    name: "taxon-map",
    reducers: {
        setSearchResult(state, action: PayloadAction<MapResponse>) {
            state.searchResult = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setActiveSample(state, action: PayloadAction<MapItem | undefined>) {
            state.activeSample = action.payload;
        },
    },
});
export default mapSlice;

export const { setActiveSample } = mapSlice.actions;

// ----------------------------------------------------------------------------
// Selectors

export const selectActiveSample = (state: RootState): MapItem | undefined => {
    return state?.map.activeSample;
};

export const selectIsLoading = (state: RootState): boolean => {
    return state?.map.loading;
};

export const selectSearchResults = (state: RootState): MapItem[] | undefined => {
    return state?.map.searchResult;
};

// ----------------------------------------------------------------------------
// Thunks

export const searchMapTaxa = (mapQuery: MapQuery): AppThunk => {
    return async dispatch => {
        // If no type is selected, we can just return an empty result without hitting the backend
        if (!mapQuery.type) {
            return dispatch(mapSlice.actions.setSearchResult([]));
        }

        dispatch(mapSlice.actions.setLoading(true));
        const query = qs.stringify(mapQuery);
        const res = await api(`map?${query}`);
        const data = await res.json();
        const results: MapResponse = data.results || data;

        dispatch(mapSlice.actions.setLoading(false));
        return dispatch(mapSlice.actions.setSearchResult(results));
    };
};
