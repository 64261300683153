import React from "react";
import { TextField } from "@material-ui/core";
import { DataError } from "../../types/form-types";
import DetailFormFieldWrapper from "../detail-form/detail-form-field-wrapper";

type DetailFormTextfieldProps = {
    handleChange: (e: any) => void;
    label: string;
    isReadOnly: boolean;
    value?: any;
    error?: DataError | null;
    formatReadOnlyValue?: (input: string | null) => string;
    isOneLineOnly?: boolean;
};

function DetailFormTextfield({
    handleChange,
    label,
    isReadOnly,
    value,
    error,
    formatReadOnlyValue,
    isOneLineOnly,
}: DetailFormTextfieldProps) {
    const prettyValue =
        isReadOnly && formatReadOnlyValue ? formatReadOnlyValue(value as string) : value;

    const textfield = (
        <TextField
            label={label}
            value={prettyValue}
            InputProps={{
                readOnly: isReadOnly,
                disableUnderline: isReadOnly,
            }}
            onChange={handleChange}
            style={{
                display: "block",
                textOverflow: isOneLineOnly ? "ellipsis" : "clip",
            }}
            fullWidth
            multiline={!isOneLineOnly}
        />
    );

    return (
        <DetailFormFieldWrapper
            body={textfield}
            isReadOnly={isReadOnly}
            error={error}
            fullWidth={isOneLineOnly}
        />
    );
}

export default DetailFormTextfield;
