import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { FilterListItem } from "../filter-dropdown/filter-dropdown";
import { addEmptyFilterOption } from "../../modules/detail-page-helpers/detail-page-helpers";

type AutocompleteSelectProps = {
    handleChange: (event: any) => void;
    label: string;
    value?: string;
    addEmptyOption?: boolean;
    getOptions?: (query: string) => Promise<FilterListItem[]>;
};

function AutocompleteSelectAsync({
    handleChange,
    getOptions,
    label,
    value,
    addEmptyOption,
}: AutocompleteSelectProps) {
    const [menuItems, setMenuItems] = React.useState<FilterListItem[]>([]);
    const [inputValue, setInputValue] = React.useState<string>(value || "");

    React.useEffect(() => {
        async function go() {
            if (!getOptions) throw Error("please provide getOptions func");
            const options = await getOptions(inputValue);
            setMenuItems(options);
        }
        go();
    }, [getOptions, inputValue]);

    React.useEffect(() => {
        setInputValue(d => value || d);
    }, [value]);

    const displayOptions = React.useMemo(() => {
        let vals = menuItems;
        if (value && !menuItems.find(d => d.key === value)) {
            vals = [
                ...menuItems,
                {
                    key: value || "-",
                    label: value || "-",
                },
            ];
        }
        // We may want to add an empty option
        return addEmptyOption ? addEmptyFilterOption(vals, "None") : vals;
    }, [menuItems, value, addEmptyOption]);

    // Value shown should be the value corresponding to the chosen key
    const displayValue = React.useMemo(() => {
        const item = displayOptions.find(m => m.key === value);
        return item || null;
    }, [displayOptions, value]);

    return (
        <div style={{ marginBottom: 10, marginTop: 15, maxWidth: "100%", width: "100%" }}>
            <Autocomplete
                options={displayOptions}
                value={displayValue}
                onChange={(event, value) => {
                    handleChange({ target: { value: value?.key || "" }, event: event });
                }}
                getOptionSelected={(option, value) => option.label === value.label}
                getOptionLabel={option => option.label}
                style={{ width: "100%", maxWidth: 700 }}
                filterOptions={(options, state) => options}
                renderInput={params => <TextField {...params} label={label} variant="outlined" />}
                inputValue={inputValue}
                onInputChange={(event, value) => {
                    if (!event) return;
                    setInputValue(value);
                }}
                renderOption={(option, { inputValue }) => {
                    const splitString = option.label.split(";");

                    return (
                        <div>
                            {splitString.map((s, i) => {
                                const index = s.toLowerCase().indexOf(inputValue.toLowerCase());
                                const bitToReplace = s.slice(index, index + inputValue.length);
                                return (
                                    <div
                                        key={s}
                                        style={i !== 0 ? { fontSize: 12 } : undefined}
                                        dangerouslySetInnerHTML={{
                                            __html: s.replace(
                                                bitToReplace,
                                                `<span style="font-weight:700;">${bitToReplace}</span>`
                                            ),
                                        }}
                                    ></div>
                                );
                            })}
                        </div>
                    );
                }}
            />
        </div>
    );
}

export default AutocompleteSelectAsync;
