import React from "react";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DetailFormFieldWrapper from "../detail-form/detail-form-field-wrapper";

type DetailDatepickerFilterProps = {
    handleChange: (e: MaterialUiPickersDate) => void;
    label: string;
    isReadOnly: boolean;
    value: string;
};

function DetailPageDatePicker({
    handleChange,
    label,
    isReadOnly,
    value,
}: DetailDatepickerFilterProps) {
    const datePicker = (
        <DatePicker
            label={label}
            value={value || null}
            style={{ width: "100%" }}
            format="DD/MM/YYYY"
            onChange={handleChange}
            disabled={isReadOnly}
        />
    );

    return (
        <DetailFormFieldWrapper
            body={datePicker}
            isReadOnly={isReadOnly}
            error={{ error: false }}
        />
    );
}

export default DetailPageDatePicker;
